import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './screens/home/home.component';
import { LoginComponent } from './screens/auth/login/login.component';
import { RecoverPwdComponent } from './screens/auth/recover-pwd/recover-pwd.component';
import { ResetPwdComponent } from './screens/auth/reset-pwd/reset-pwd.component';
import { SignupComponent } from './screens/auth/signup/signup.component';
import { Page404Component } from './screens/page404/page404.component';
import { AdminAuthGuardService as AdminAuthGuard, AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { AdminHomeComponent } from './screens/admin/admin-home/admin-home.component';
import { ImagesComponent } from './screens/admin/images/images.component';
import { ImgixEditorComponent } from './dialogs/imgix-editor/imgix-editor.component';
import { ContactUsComponent } from './screens/contact-us/contact-us.component';
import { SupportComponent } from './screens/support/support.component';
import { RegistrationComponent } from './screens/registration/registration.component';
import { MadisonComponent } from './screens/madison/madison.component';
import { FaqComponent } from './screens/faq/faq.component';
import { TeamComponent } from './screens/team/team.component';
import { PrivacyPolicyComponent } from './screens/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './screens/terms-of-service/terms-of-service.component';
import { EventsComponent } from './screens/events/events.component';
import { PhotosComponent } from './screens/photos/photos.component';
import { TestimoniesComponent } from './screens/testimonies/testimonies.component';
import { DonationReceiptComponent } from './screens/donation-receipt/donation-receipt.component';
import { AccountPaymentSourcesComponent } from './components/account/account-payment-sources/account-payment-sources.component';
import { AccountPersonalDetailsComponent } from './components/account/account-personal-details/account-personal-details.component';
import { AccountComponent } from './components/account/account.component';
const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'contact', component: ContactUsComponent },
  { path: 'support', component: SupportComponent },
  { path: 'apply', component: RegistrationComponent },
  { path: 'madison', component: MadisonComponent },
  { path: 'info', component: FaqComponent },
  { path: 'photos', component: PhotosComponent },
  { path: 'testimonies', component: TestimoniesComponent },
  { path: 'team', component: TeamComponent },
  { path: 'pp', component: PrivacyPolicyComponent },
  { path: 'tos', component: TermsOfServiceComponent },
  { path: 'events', component: EventsComponent },
  { path: 'view-donation/:id', component: DonationReceiptComponent },

  { path: 'login', component: LoginComponent },
  { path: 'recover-pwd', component: RecoverPwdComponent },
  { path: 'reset-pwd', component: ResetPwdComponent },
  { path: 'signup', component: SignupComponent },
  {
    path: 'my-account',
    component: AccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'my-account/all-payment-sources',
    component: AccountPaymentSourcesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'my-account/edit-details',
    component: AccountPersonalDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    component: AdminHomeComponent,
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'admin/edit-image/:imageID',
    component: ImgixEditorComponent,
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'admin/images',
    children: [
      { path: '**', component: ImagesComponent }
    ],
    component: ImagesComponent,
    canActivate: [AdminAuthGuard],
  },

  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
