import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import * as typeformEmbed from '@typeform/embed';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements AfterViewInit, OnInit {

  @ViewChild('form') div;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle( 'Apply - The Maker Heals' );
  }

  ngAfterViewInit(): void {
    typeformEmbed.makeWidget(this.div.nativeElement, 'https://madisonmissions.typeform.com/to/G4xPHu', {
      hideFooter: true,
      hideHeaders: true,
      opacity: 0,
    });
    setTimeout(() => {
      this.document.getElementById('loaderFrame').remove();
    }, 2000);
  }

}
