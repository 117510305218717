import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-first-seminar',
  templateUrl: './first-seminar.component.html',
  styleUrls: ['./first-seminar.component.scss']
})
export class FirstSeminarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
