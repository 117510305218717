import { DbService } from './db.service';
import { AuthService } from './auth.service';
import { StripeCustomer } from './donationPmt.service';

interface DataStoreInterface {
    singletons: {
        [propName: string]: any
    };
    sets: {
        [propName: string]: Set<any>;
    };
    db?: DbService;
    auth?: AuthService;
    lastPageUrl: string[];
    activeDomain?: string;
}

export const DataStore: DataStoreInterface = {
    singletons: {},
    sets: {},
    lastPageUrl: []
};

export interface GenericRow {
    ID?: string;
    Owner?: string;
    OwnerObj?: PersonType;
    createdAt?: string;
    updatedAt?: string;
    __typename?: string;
    isCommon?: boolean;
    typeSuffix?: string;
}

export type AllRowTypes = GenericRow | ImageType | AddressType | ImageSettingFavType;

export interface UploaderProgress {
    percent: number;
    done: boolean;
}

export interface ImageType extends GenericRow {
    name: string;
    _Folder: string;
    path: string;
    width: number;
    height: number;
    host: string;
    uploader?: UploaderProgress;
    unsplash?: boolean;
    params?: string;
}

export interface ImageSettingFavType extends GenericRow {
    name: string;
    values: { [key: string]: string | number };
}

export interface FolderType extends GenericRow {
    parent: string;
    name: string;
}

export interface PersonType extends GenericRow {
    First: string;
    Last: string;
    Email: string;
    Password: string;
    TopAdmin: boolean;
    Mobile: string;
    StripeCustomer?: StripeCustomer;
    Addresses?: AddressType[];
    Donations?: DonationType[];
}

export interface AddressType extends GenericRow {
    name: string;
    mobile: string;
    instructions: string;
    address1: string;
    address2: string;
    suburb: string;
    postcode: string;
    state: string;
}


export interface RecipeType extends GenericRow {
    name: string;
    image?: ImageType;
    description: string;
    ingredients?: string;
    allowedTemp: string;
    type: 'main' | 'dessert';
}

export type BooleanString = 'true' | 'false';

export type FrequencyString = 'onetime' | 'monthly';

export interface DonationType extends GenericRow {
    DonationID: string;
    Amount: number;
    Recurring: BooleanString;
    First: string;
    Last: string;
    Email: string;
    Comments: string;
    CardBrand: string;
    CardExpMonth: number;
    CardExpYear: number;
    CardID: string;
    CardLast4: string;
    Person?: string;
    Frequency?: FrequencyString;
    Active?: BooleanString;
    Website: 'makerheals.org.au' | 'madison.org.au';
}
