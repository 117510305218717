import { DbService } from 'src/app/services/db.service';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { UploaderProgress, ImageType } from './DataStore';

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

interface SignedUrlResponse {
  url?: string;
  key?: string;
}

interface UploadType {
  file: File;
  progress: UploaderProgress;
  isUploading: boolean;
  resolve: (value?: any) => void;
  progressCallback: (percent: number) => void;
}

@Injectable({
  providedIn: 'root'
})
export class UploaderService {

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private db: DbService
  ) { }

  Queue: Set<UploadType> = new Set();
  NumUploading = 0;

  GetSignedUrlLambdaEndpoint = 'https://api-v3.tuilder.com/v3/beehiveupload';

  addToQueue(
    file: File,
    progress: UploaderProgress,
    resolve: (value?: any) => void,
    progressCallback?: (percent: number) => void
  ) {
    const Item = { file, progress, resolve, isUploading: false, progressCallback };
    this.Queue.add(Item);
    this.processQueue();
  }

  processQueue() {

    for (const item of this.Queue) {

      if (!item.isUploading) {

        if (this.NumUploading > 3) {
          break;
        }

        this.sendFile(item);
        item.isUploading = true;

        this.NumUploading++;

      }
    }

  }

  getImageDimensions(file: File): Promise<{ width: number, height: number }> {

    const URL = window && (window.URL || window['webkitURL']) || null;

    return new Promise(resolve => {

      const img = new Image();

      img.src = URL.createObjectURL(file);

      img.onload = () => {

        const width = img.naturalWidth;
        const height = img.naturalHeight;

        URL.revokeObjectURL(img.src);

        resolve({
          width, height
        });

      };

    });

  }

  pickImage(progressCallback: (percent: number) => void) {
    return new Promise(resolve => {

      const el = document.createElement('input');
      el.type = 'file';
      el.multiple = true;
      el.onchange = (e: HTMLInputEvent) => {

        const file = e.target.files[0];

        const NewImage: ImageType = {
          ID: this.db.utils.uid(),
          name: file.name,
          _Folder: null,
          height: 0,
          width: 0,
          path: null,
          host: 'https://cdn2.tda.website/',
          __typename: 'Image'
        };

        const Progress: UploaderProgress = {
          percent: 0,
          done: false,
        };

        NewImage.uploader = Progress;

        const ImageDimensions = this.getImageDimensions(file);

        this.upload(file, Progress, progressCallback).then((fileUrl: string) => {

          NewImage.path = fileUrl;

          ImageDimensions.then(dimensions => {

            NewImage.height = dimensions.height;
            NewImage.width = dimensions.width;

            delete NewImage.uploader;

            this.db.put(NewImage);

            resolve(NewImage);

          });
        });

      };

      el.click();

    });
  }

  deleteFile(path: string) {

    const Session = this.auth.getSessionValue();

    let Url = this.GetSignedUrlLambdaEndpoint;

    Url += '?path=' + encodeURI(path);

    Url += '&session=' + Session;

    Url += '&delete=1';


    this.http.get(Url).subscribe((response) => {
      console.log(response);
    });

  }

  sendFile(upload: UploadType) {

    console.log('sending file', upload);


    const Session = this.auth.getSessionValue();

    let Url = this.GetSignedUrlLambdaEndpoint;

    Url += '?filename=' + encodeURI(upload.file.name);

    Url += '&session=' + Session;

    Url += '&account=' + this.auth.personID;

    this.http.get(Url).subscribe((response: SignedUrlResponse) => {

      if (response.url) {

        const req = new HttpRequest('PUT', response.url, upload.file, {
          reportProgress: true,
        });

        this.http.request(req).subscribe(event => {

          // Via this API, you get access to the raw event stream.
          // Look for upload progress events.
          if (event.type === HttpEventType.UploadProgress) {

            // This is an upload progress event. Compute and show the % done:
            const percentDone = Math.round(100 * event.loaded / event.total);
            upload.progress.percent = percentDone;

            if (upload.progressCallback) {
              upload.progressCallback(percentDone);
            }

          } else if (event instanceof HttpResponse) {
            upload.progress.percent = 100;
            upload.progress.done = true;
            upload.resolve(response.key);
            this.Queue.delete(upload);
            this.NumUploading--;
            this.processQueue();
          }
        });

      }

    });
  }

  upload(file: File, progress: UploaderProgress, progressCallback?: (percent: number) => void) {

    const promise = new Promise((resolve) => {

      this.addToQueue(file, progress, resolve, progressCallback);

    });

    this.db.addOngoingRequest(promise);

    return promise;

  }

}
