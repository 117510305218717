import { CallResponse } from './../../../services/db.service';
import { AuthService } from './../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

export interface LoginData {
  email: string;
  password: string;
  remember: string;
}

export interface LoginResponse extends CallResponse {
  verifyError: 1 | 0;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  activity = false;
  loginForm: FormGroup;
  hidePwd = true;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.loginForm = new FormGroup({

      email: new FormControl('', [Validators.required, Validators.email]),

      password: new FormControl('', [Validators.required, Validators.pattern(
        /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/
      )]),

      remember: new FormControl('*')

    });

  }

  public hasError = (controlName: string, errorName: string) => {
    return this.loginForm.controls[controlName].hasError(errorName);
  }

  async login(data: LoginData) {

    this.activity = true;

    const LoginSuccess = await this.auth.login(data);

    this.activity = false;

    if (LoginSuccess) {

      const returnRoute: string = this.route.snapshot.queryParamMap.get('return');

      if (returnRoute) {
        this.router.navigateByUrl(returnRoute);
      } else {
        this.router.navigateByUrl('/my-account');
      }

    }

  }

}
