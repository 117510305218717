<app-menu></app-menu>
<div class="wrapper">
    <h1>Inf<span class="alt-font">o</span> &amp; <span class="alt-font">F</span>requently Asked Questi<span class="alt-font">o</span>ns</h1>
    <h2>What is "The Maker Heals" program?</h2>
    <p>"The Maker Heals" is an experiential, practical, spiritually-focused, natural-healing, live-in or digital lifestyle program.</p>
    <h2>Who is "the Maker"?</h2>
    <p>The Maker is the creator of our natural world, and all things beautiful and good. The Creator has given simple, yet powerful laws of health. These laws and the Creator's will are revealed through nature and through the writings of Scripture. The Creator's
        way is a way of love, compassion and care for everyone. </p>
    <p>We encourage you to experience for yourself, the truth to the words "The Maker Heals".</p>

    <h2>Who is your team?</h2>
    <p>Our team are a diverse, compassionate and genuinely caring group of Seventh-day Adventist Christians. We love health, food, nature, the scriptures and the spiritual life that we have experienced and are passionate to share. </p>
    <p>On the team we have expert cooks, well-travelled and researched presenters, natural therapists, and many combined years of experience working in health retreats in Australia and overseas.</p>

    <h2>Why is this program Donation-basis?</h2>
    <p>We wish everyone to prosper, and be in good health, physically, emotionally, mentally and spiritually. We want everyone who needs this experience, wealthy or poor to be able to afford it. Those who can donate more, can do so, those who can't afford
        will donate less. We give this choice to our patrons/guests.</p>
    <p>Especially because of COVID-19, we understand that many may be in financially hard times.</p>
    <p>This is our way to give back to the community.</p>
    <p>Our parent organisation (Madison Missions) has a big vision to help teach young people business, health and Christian principles. For income, Madison is running Beehive Community Services. The Maker Heals is possible to be run on donation-basis because
        of their hard work and because of the generous community. To read about this, and our full vision, visit: <a href="httpps://madison.org.au/" target="_blank">www.madison.org.au</a></p>

    <h2>What is the food like?</h2>
    <p>All the food is 100% plant-based and 100% delicious.🙂🍎 During your stay we may give you options of a short fruit-fast or water-fast and discuss resetting your food portion-sizes and meal-times. For more information please contact us.
    </p>

    <h2>What are the dates?</h2>
    <p>After the pilot of the new digital program, being planned for late July 2021, we will be announcing new dates.</p>

    <!-- <h2>What are the accommodation and facilities like?</h2>

    <p>Accomodation is very comfortable. Each room has an ensuite and shower.</p>
    <p>If we have high capacity, you may be sharing your room with another guest of the same gender.</p>

    <h2>Does the program include treatments?</h2>
    <p>Yes and no. We give simple massage and natural hydrotherapy treatments as needed.</p>
    <p>However, typical treatments that have been offered at Abide in the past, are not part of this program.</p>
    <p>Additional treatments may be purchased from Madison Missions on request.</p>

    <h2>Do you accept children?</h2>
    <p>The nature of this program is generally not appropriate for young children, so we recommend that you take this opportunity to get much-needed rest and decide for the care of your children by others while you are here with us.</p>

    <h2>Can I get mobile coverage and internet there?</h2>
    <p>Yes, Mobile 4G internet signal is available for Optus / Telstra.</p>

    <h2>Is there a local train station or airport that you can pick me up from?</h2>
    <p>Yes, we can arrange pickup from Lilydale Train station or Melbourne airport (Tullamarine). Recommended donation of $50 to cover costs.</p>

    <h2>What do I need to bring and how should I dress?</h2>
    <p>The program is informal, including meal times, so dress comfortably so that you can enjoy walking and exercising.</p>
    <p>Don’t forget to bring both warm and cool weather clothing, comfortable walking shoes, and possibly gumboots as the walking tracks are damp during wet weather.</p>
    <p>&nbsp;</p>
    <p><app-button label="Apply to Come" routerLink="/apply" theme="primary"></app-button></p> -->

</div>
<app-first-seminar></app-first-seminar>
<app-subscribe></app-subscribe>
<app-footer></app-footer>