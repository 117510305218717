import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import * as typeformEmbed from '@typeform/embed';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit, AfterViewInit {

  @ViewChild('form') div;

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
// https://mail654163.typeform.com/to/G4xPHu
    typeformEmbed.makeWidget(this.div.nativeElement, 'https://madisonmissions.typeform.com/to/DUWIcSqS', {
      hideFooter: true,
      hideHeaders: true,
      opacity: 0,
    });

  }

}
