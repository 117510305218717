import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { GenericRow } from './DataStore';

export function strToHTML(str: string): HTMLElement {

  const template = document.createElement('template');
  template.innerHTML = str.trim();

  return template.content.firstElementChild as HTMLElement;

}

export interface TintType {
  tint: string;
  color: string;
}

type GlobalEvents = 'scroll' | 'resize' | 'mousedown' | 'mouseup';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  lodash = _;

  scriptCache = {};
  scriptProcessing = {};
  lo = _;
  eventCallbacks: any = {};
  eventCallbackFunctions: any = {};

  GlobalEventCallbacks = {};
  GlobalEventIsWatching = {};

  replaceValue(obj: any, value: any, replace: any) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === value) {
        obj[key] = replace;
      }
    });
    return obj;
  }

  chunk(array: any[], size: number) {
    return _.chunk(array, size);
  }

  dateDesc(ar: GenericRow[], key: string = 'createdAt') {
    ar.sort((a, b) => {
      return ('' + (a[key])).localeCompare((b[key]));
    }).reverse();
  }

  shuffle(array: any[]) {
    let currentIndex = array.length;
    let temporaryValue: any;
    let randomIndex: number;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  debounce(func: any, wait: number, immediate?: boolean) {
    let timeout;
    return function () {
      const context = this;
      const args = arguments;
      const later = () => {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  }

  QueryParamsFromUrl(url: string): { [key: string]: string } {

    if (url.includes('?')) {

      const output: any = {};

      const QueryStr = url.split('?')[1];
      const Params: any = {};
      QueryStr.split('&').forEach(param => {
        const bit = param.split('=');
        output[bit[0]] = bit[1];
      });

      return output;

    } else {
      return {};
    }

  }

  RemoveEventCallback(eventName: GlobalEvents, uid: string) {
    if (this.GlobalEventCallbacks[eventName]) {
      delete this.GlobalEventCallbacks[eventName][uid];
    }
  }

  RunEventCallbacks(eventName: GlobalEvents, event: any) {

    if (this.GlobalEventCallbacks[eventName]) {
      Object.keys(this.GlobalEventCallbacks[eventName]).forEach(uid => {
        const Fn = this.GlobalEventCallbacks[eventName][uid];
        Fn(event);
      });
    }

  }

  WhenEventHappens(eventName: GlobalEvents, fn: () => void): string {

    const IsWatching = this.GlobalEventIsWatching[eventName];

    const uid = this.uid();
    this.GlobalEventCallbacks[eventName] = this.GlobalEventCallbacks[eventName] || {};
    this.GlobalEventCallbacks[eventName][uid] = fn;

    if (!IsWatching) {

      if (eventName === 'scroll' || eventName === 'resize') {
        window.addEventListener(eventName, (event) => {
          this.RunEventCallbacks(eventName, event);
        });
      } else {
        document.addEventListener(eventName, (event) => {
          this.RunEventCallbacks(eventName, event);
        });
      }

      this.GlobalEventIsWatching[eventName] = true;

    }
    return uid;
  }

  whenWindowScrolls(fn) {
    return this.WhenEventHappens('scroll', fn);
  }

  removeScroll(uid) {
    return this.RemoveEventCallback('scroll', uid);
  }

  whenWindowResizes(fn) {
    return this.WhenEventHappens('resize', fn);
  }

  removeResize(uid) {
    return this.RemoveEventCallback('resize', uid);
  }

  StopDocEvent(EventName: string) {
    document.removeEventListener(EventName, this.eventCallbackFunctions[EventName]);
    delete this.eventCallbackFunctions[EventName];
    this.eventCallbacks[EventName].length = 0;
  }

  StartDocEvent(EventName: string, callback: any) {

    this.eventCallbacks[EventName] = this.eventCallbacks[EventName] || [];

    if (!this.eventCallbackFunctions[EventName]) {
      this.eventCallbackFunctions[EventName] = (event) => {
        this.eventCallbacks[EventName].forEach((callbackFn) => {
          callbackFn(event);
        });
      };
    }

    if (!this.eventCallbacks[EventName].length) {

      document.addEventListener(EventName, this.eventCallbackFunctions[EventName]);

    }

    this.eventCallbacks[EventName].push(callback);

  }

  pwdgen(length: number) {

    let NewPwd = this._generatePassword(length);

    while (!NewPwd.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&'#])[A-Za-z\d@$!%*?&'#]{8,}$/)) {
      NewPwd = this._generatePassword(length);
    }

    return NewPwd;

  }

  _generatePassword(length: number) {

    const pattern = /[a-zA-Z0-9_\-\+\.@$!%*?&]/;

    return Array.apply(null, { length })
      .map(() => {
        let result;
        while (true) {
          result = String.fromCharCode(this._getRandomByte());
          if (pattern.test(result)) {
            return result;
          }
        }
      }, this)
      .join('');
  }

  _getRandomByte() {
    // http://caniuse.com/#feat=getrandomvalues
    if (window.crypto && window.crypto.getRandomValues) {
      const result = new Uint8Array(1);
      window.crypto.getRandomValues(result);
      return result[0];
    } else {
      return Math.floor(Math.random() * 256);
    }
  }

  unsubscribe(subs) {
    _.forEach(subs, (sub) => {
      sub.unsubscribe();
    });
  }

  uppercaseFirst(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  lowercaseFirst(str: string): string {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  randomChars(num: number): string {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let text = '';
    for (let i = 0; i < num; i++) {
      text += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return text;
  }

  uid() {
    // return uuid();
    return (this.randomChars(4)) + Date.now().toString(36) + (this.randomChars(4));
  }

  shortUid() {
    return (this.randomChars(4)) + Date.now().toString(36);
  }

  isObject(item: any) {
    return _.isObject(item);
  }

  isArray(item: any) {
    return _.isArray(item);
  }


  // JsonClone effectively deep clones an object by stringifying to JSON and re-parsing
  JsonClone(item: any) {

    return JSON.parse(JSON.stringify(item));

  }

  removeClassesThatMatch(Node: HTMLElement, pattern: RegExp) {

    // Remove existing font class
    Node.classList.forEach(name => {
      if (name.match(pattern)) {
        Node.classList.remove(name);
      }
    });

  }

  getDirectChildWithClass(ParentNode: HTMLElement, className: string[]) {

    let MatchedNode: HTMLElement;

    for (const ChildNode of Array.from(ParentNode.childNodes)) {
      const ChildHtmlNode = ChildNode as HTMLElement;

      let AllMatched = true;

      className.forEach(name => {
        if (!ChildHtmlNode.classList || !ChildHtmlNode.classList.contains(name)) {
          AllMatched = false;
        }
      });

      if (AllMatched) {

        MatchedNode = ChildHtmlNode;

        break;
      }

    }

    return MatchedNode;

  }

  clone(item: any, deep?: boolean) {
    if (deep) {
      return _.cloneDeep(item);
    } else {
      return _.clone(item);
    }
  }

  each(obj: any, callback: any) {

    Object.keys(obj).forEach((key) => {

      callback(obj[key], key);

    });

  }

  // Recursive deep extend
  deepExtend(destination: any, source: any, removeNonexistentKeys?: boolean) {

    // remove nonexistent keys from desination
    if (removeNonexistentKeys &&
      !_.isArray(source) && _.isObject(source) &&
      !_.isArray(destination) && _.isObject(destination)
    ) {
      const keys2Delete = _.difference(_.keys(destination), _.keys(source));
      _.each(keys2Delete, (key) => {
        delete destination[key];
      });
    }

    // loop through source, extending destination
    _.each(source, (value, key) => {

      if (_.isArray(value)) {

        if (!_.isArray(destination[key])) {
          destination[key] = [];
        }

        if (removeNonexistentKeys) {
          destination[key].length = 0;
        }
        _.each(value, (itm) => {
          // Get index of the item in the destination
          const ExistingIndex = destination[key].indexOf(itm);
          // Only push item if it doesn't already exist in the array
          if (ExistingIndex < 0) {
            destination[key].push(itm);
          }
        });

      } else if (_.isObject(value)) {

        if (!destination[key]) {
          destination[key] = value;
        } else {
          this.deepExtend(destination[key], value);
        }

      } else {

        if (value !== undefined) {
          destination[key] = value;
        }

      }

    });

    return destination;

  }

  group(array, key) {
    return _.groupBy<any>(array, key) as any;
  }

  sort(destination, key) {
    return destination.sort((a, b) => {

      let GetValue: any;

      GetValue = () => {
        return 0;
      };
      if (typeof key === 'string') {
        GetValue = (obj) => {
          return obj[key] && obj[key].toString().toLowerCase() || '';
        };
      } else if (typeof key === 'function') {
        GetValue = (obj) => {
          return key(obj).toLowerCase();
        };
      }

      if (parseInt(GetValue(a), 10) === GetValue(a)) {
        return GetValue(a) - GetValue(b);
      } else if (GetValue(a) < GetValue(b)) {
        return -1;
      } else if (GetValue(a) > GetValue(b)) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  extend(destination, source) {
    return _.extend(destination, source);
  }

  merge(destination, source) {
    return _.merge(destination, source);
  }

  timeStrFromDate(dateObj) {

    let Hours = dateObj.getHours();
    Hours = ('0' + Hours).slice(-2);

    let Minutes = dateObj.getMinutes();
    Minutes = ('0' + Minutes).slice(-2);

    const output = Hours + ':' + Minutes;

    return output;

  }

  watch(oObj, sProp) {

    const sPrivateProp = '$_' + sProp + '_$'; // to minimize the name clash risk
    oObj[sPrivateProp] = oObj[sProp];

    // overwrite with accessor
    Object.defineProperty(oObj, sProp, {
      get: () => {
        return oObj[sPrivateProp];
      },

      set: (value) => {
        console.warn(value);
        // tslint:disable-next-line: no-debugger
        debugger; // sets breakpoint
        oObj[sPrivateProp] = value;
      }
    });

  }

  parsePersonName(name: string) {

    name.replace(/\s+/, ' ').trim();
    const bits = name.split(' ');

    return {
      first: bits.shift(),
      last: bits.join(' '),
    };

  }

  parseDateTime(obj) {

    const time = obj.timepicker.split(':');
    if (time.length === 2) {

      const Hour = parseInt(time[0], 10);
      const Minute = parseInt(time[1], 10);

      obj.date.setHours(Hour);
      obj.date.setMinutes(Minute);

    }

    obj.time = obj.date.getTime();
    delete obj.date;

  }

  colorFromString(str) {

    const hashCode = (hashCodeString: string) => { // java String#hashCode
      let hash = 0;
      for (let i = 0; i < hashCodeString.length; i++) {
        // tslint:disable-next-line: no-bitwise
        hash = hashCodeString.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    };

    const intToRGB = (i) => {
      // tslint:disable-next-line: no-bitwise
      const c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

      return '00000'.substring(0, 6 - c.length) + c;

    };

    return intToRGB(hashCode(str));

  }

  loadScript(url) {

    const promise = new Promise((resolve, reject) => {

      if (this.scriptProcessing[url]) {
        return;
      }

      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');

      this.scriptProcessing[url] = 1;

      if (this.scriptCache[url]) {
        resolve();
        this.scriptProcessing[url] = 0;
      } else {
        const complete = () => {
          this.scriptProcessing[url] = 0;
          resolve();
        };
        script.type = 'text/javascript';
        script.src = url;

        // script['onreadystatechange'] = complete;
        script.onload = complete;
        this.scriptCache[url] = 1;

        head.appendChild(script);
      }

    });

    return promise;

  }

  setCookie(name: string, value: string, days: number) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  getCookieValue(key: string) {
    const b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
  }

  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  normalizeMobile(mobile: string) {
    if (typeof mobile === 'number') {
      const mobileNum = mobile as number;
      mobile = mobileNum.toString();
    }
    if (mobile.length === 9 && mobile.charAt(0) === '4') {
      mobile = '0' + mobile;
    }
    mobile = mobile.replace(/\s+/g, '');
    mobile = mobile.replace(/^\+614/, '04');
    mobile = mobile.replace(/^614/, '04');
    return mobile;
  }

  strip(html: string) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  delete(source, row, idx?) {
    if (_.isUndefined(idx)) {
      idx = _.indexOf(source, row);
    }
    if (idx > -1) {
      source.splice(idx, 1);
    }
  }

  deleteByVal(source, key, val) {
    if (!source) {
      return;
    }
    for (let i = source.length - 1; i >= 0; i--) {
      if (source[i][key] === val) {
        source.splice(i, 1);
      }
    }
    return source;
  }

  addSum(nums: any) {

    let output = 0;
    _.each(nums, (n) => {
      output += (parseFloat(n || 0) || 0);
    });

    return output;

  }

  getMonthShortName(monthNumber: number) {

    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    return monthNames[monthNumber] || 'Unkown';

  }

  getFormData(form: FormGroup) {

    const data = {};

    Object.keys(form.controls).forEach(key => {

      data[key] = form.get(key).value;

    });

    return data;

  }

  find(haystack, key, needle?) {

    return this.shortcut('find', haystack, key, needle);

  }

  filter(haystack, key, needle?) {

    return this.shortcut('filter', haystack, key, needle);

  }

  shortcut(cmd, haystack, key, needle?) {

    if (!_.isUndefined(needle)) {
      return _[cmd](haystack, (row) => {
        return row[key] === needle;
      });
    } else {
      return _[cmd](haystack, (row) => {
        return row[key];
      });
    }

  }

  IsorHasParentOfClass(el: HTMLElement, className: string) {

    if (!el.classList) {
      return false;
    }

    if (el.classList.contains(className)) {
      return el;
    } else if (el.parentNode) {
      return this.IsorHasParentOfClass(el.parentNode as HTMLElement, className);
    } else {
      return false;
    }

  }

  colorIsDark(hex: string): boolean {

    return this.luminanceFromHex(hex) < 60;

  }

  hexToRGB(hex: string): [number, number, number] {

    const rgb: [number, number, number] = [0, 0, 0];

    hex = hex.substr(1);

    for (let i = 0; i < 3; i++) {

      rgb[i] = parseInt(hex.substr(i * 2, 2), 16);

    }

    return rgb;

  }

  rgbToHex(r: number, g: number, b: number) {

    let output = '#';

    [r, g, b].forEach(val => {

      val = Math.round(val);

      let hexDec = val.toString(16);

      if (hexDec.length < 2) {
        hexDec = '0' + hexDec;
      }

      output += hexDec;

    });

    return output;

  }

  // Takes a hex color, with a label
  // Returns an array of all lighter and darker shades, incremented by 10%;

  getColorTintsAndShades(hex: string): TintType[] {

    const output: TintType[] = [];

    const rgb = this.hexToRGB(hex);

    const TenPercentOfSpectrum = 0.1 * 255;

    // First get shades

    let Shades = [];

    const NotLessThanZero = (num: number) => {
      return Math.max(num, 0);
    };

    const NotGreaterThan255 = (num: number) => {
      return Math.min(num, 255);
    };

    const RoundToNearest10 = (num: number) => {
      return Math.ceil(num / 10) * 10;
    };

    const RgbSum = rgb.reduce((a, b) => a + b, 0);
    const RgbAverage = RgbSum / 3;

    // Starting with the brightest rgb value (Math.max)
    // Continue decreasing darkness until we reach 0
    // const MaxRgbVal = Math.sum(...rgb);

    for (let i = TenPercentOfSpectrum; i < RgbAverage; i += TenPercentOfSpectrum) {
      const LuminancePercent = RoundToNearest10(((RgbAverage - i) / 255) * 100);
      // if (LuminancePercent > 0) {
      Shades.push(
        {
          tint: `${LuminancePercent}`,
          color: this.rgbToHex(
            NotLessThanZero(rgb[0] - i),
            NotLessThanZero(rgb[1] - i),
            NotLessThanZero(rgb[2] - i)
          )
        });
      // }
    }

    // Reverse to start with the darkest shades, getting lighter
    Shades = Shades.reverse();

    // Push shades to output
    output.push(...Shades);

    // Push Actual Color
    output.push({
      tint: `${RoundToNearest10((RgbAverage / 255) * 100)}`,
      color: hex
    });

    const Tints = [];

    // Starting with the darkest rgb value (Math.min)
    // Continue increasing brightness until we reach 255
    for (let i = TenPercentOfSpectrum; i < 255 - RgbAverage; i += TenPercentOfSpectrum) {
      const LuminancePercent = RoundToNearest10(((i + RgbAverage) / 255) * 100);
      Tints.push({
        tint: `${LuminancePercent}`,
        color: this.rgbToHex(
          NotGreaterThan255(rgb[0] + i),
          NotGreaterThan255(rgb[1] + i),
          NotGreaterThan255(rgb[2] + i)
        )
      });
    }

    // Push tints to output
    output.push(...Tints);

    return output;

  }

  // Take hex string, convert to RGB
  // Add luminance % (represents a percentage of 255)
  // convert back to hex & return
  addLuminance(hex: string, LumPercent: number): string {

    let output = '';

    const rgb = this.hexToRGB(hex);

    const Negative = LumPercent < 0;

    LumPercent = (Math.abs(LumPercent) / 100);

    const LumAmount = Math.round(LumPercent * 255);

    const ModifiedRGB: [number, number, number] = [0, 0, 0];

    rgb.forEach((val, index) => {

      if (Negative) {
        val -= LumAmount;
      } else {
        val += LumAmount;
      }

      if (val < 0) {
        val = 0;
      } else if (val > 255) {
        val = 255;
      }

      ModifiedRGB[index] = val;

    });

    return this.rgbToHex(...ModifiedRGB);

  }

  luminanceFromHex(hex: string): number {

    const rgb = this.hexToRGB(hex);

    const sum = rgb.reduce((previous, current) => current += previous);

    const luminance = Math.round((sum / (255 * 3)) * 100);

    return luminance;

  }

  PlaintextEditableCheck(El: HTMLElement) {

    const div = document.createElement('div');
    const HTMLTxt = El.innerHTML.replace(/\&[#0-9a-z]+;/gi, (enc) => {
      div.innerHTML = enc;
      return div.innerText;
    });

    if (El.innerText !== HTMLTxt) {
      El.innerText = El.innerText;
    }

  }

}

export const TuilderUtils = new UtilsService();
