import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import * as typeformEmbed from '@typeform/embed';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, AfterViewInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) { }

  backLink = '';

  ngOnInit(): void {
    this.titleService.setTitle('Contact Us - The Maker Heals');
    if (this.activatedRoute.snapshot.queryParams.back) {
      this.backLink = this.activatedRoute.snapshot.queryParams.back;
    }
  }

  ngAfterViewInit(): void {

    const el = this.document.getElementById('form');

    typeformEmbed.makeWidget(el, 'https://madisonmissions.typeform.com/to/ph3sis', {
      hideFooter: true,
      hideHeaders: true,
      opacity: 0,
    });

  }

}
