<app-menu></app-menu>
<app-back-header title="Support Maker Heals" back="" theme="charcoal"></app-back-header>

<div class="section" style="padding-bottom: 0;">
    <div class="wrapper-support">
        <p>We are fundraising to establish an education and health facility.</p>

        <p>Your gift will help us continue to offer the Maker Heals Program and achieve our vision.</p>

        <p>You can read about the Madison Vision on our website here: <a href="https://madison.org.au/">www.madison.org.au</a></p>

        <h2>Donate with your card, once-off or monthly</h2>

        <app-donate></app-donate>
    </div>
</div>

<div id="happen" class="section" style="padding-top: 0;">

    <div class="wrapper-support">

        <h2>Donate via bank transfer</h2>
        <div class="grid">
            <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-165115.jpg?h=342&w=500&fit=crop&auto=format" alt="">
            <div class="txt">
                <p>Account Name: The Maker Heals</p>
                <p>BSB: 063 689</p>
                <p>Account Number: 1029 1201</p>
                <p>Reference: [Your Name]</p>
            </div>
        </div>

        <p>Please <a routerLink="/contact" [queryParams]="{back: '/support'}">contact us</a> if you have any questions.</p>

        <div class="logo-grid" style="margin-top: 50px;">
            <div class="img">
                <img src="https://cdn2.tda.website/beehive/20200607/Madison-Missions-Logo.svg" alt="Madison Missions Australia Ltd." class="madison">
            </div>
            <div class="img">
                <img src="https://cdn2.tda.website/beehive/20200607/Eastward-logo.svg" alt="Eastward Missions" class="eastward">
            </div>
            <div class="img">
                <img src="https://cdn2.tda.website/beehive/20200607/The-Maker-Heals-Logo.svg" alt="The Maker Heals" class="makerheals">
            </div>
            <div class="img">
                <img src="https://cdn2.tda.website/beehive/20200607/Beehive-Community-Services-Logo.svg" alt="Beehive Community Services" class="beehive">
            </div>
        </div>

    </div>

</div>

<app-share></app-share>
<app-subscribe></app-subscribe>
<app-footer></app-footer>