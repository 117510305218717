import { Component, OnInit, Input } from '@angular/core';
import { AddressType } from 'src/app/services/DataStore';

@Component({
  selector: 'app-show-address',
  templateUrl: './show-address.component.html',
  styleUrls: ['./show-address.component.scss']
})
export class ShowAddressComponent implements OnInit {

  @Input() adr: AddressType;

  constructor() { }

  ngOnInit(): void {
  }

}
