<app-menu></app-menu>
<app-fullscreen-image src="../../../../assets/AdobeStock_292955788.jpg">
    <div class="wrapper">

        <div class="card mat-elevation-z8">

            <h1>Recover your password</h1>

            <form [formGroup]="recoverForm" autocomplete="off" novalidate (ngSubmit)="recover(recoverForm.value)">

                <mat-form-field class="field">
                    <input matInput tabindex="1" formControlName="email" placeholder="Email" type="text">
                    <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error>
                    <mat-error *ngIf="hasError('email', 'email')">Invalid email</mat-error>
                </mat-form-field>

                <p>
                    <button type="submit" tabindex="2" mat-raised-button color="primary" [disabled]="activity || !recoverForm.valid">Recover</button>
                </p>

                <div>
                    <a routerLink="/login">Login</a>
                </div>

            </form>

        </div>
    </div>
</app-fullscreen-image>
<app-footer></app-footer>