import { Component, OnInit } from '@angular/core';
import { RecipeType } from 'src/app/services/DataStore';
import { DbService } from 'src/app/services/db.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-recipe-previews',
  templateUrl: './recipe-previews.component.html',
  styleUrls: ['./recipe-previews.component.scss']
})
export class RecipePreviewsComponent implements OnInit {

  recipes: { main: RecipeType[], dessert: RecipeType[] };
  viewing = 'main';

  constructor(
    private db: DbService,
    private utils: UtilsService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.db.list('Common:Recipe').then(recipes => {
      const recipesArray = Array.from(recipes);
      this.utils.sort(recipesArray, 'name');
      this.recipes = this.utils.group(recipesArray, 'type');
      this.recipes.main.length = 4;
    });
  }

}
