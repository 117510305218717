import { DataStore } from './../../services/DataStore';
import { DbService } from 'src/app/services/db.service';
import { Router, NavigationEnd } from '@angular/router';
import { FolderType } from 'src/app/services/DataStore';
import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-folder-browser',
  templateUrl: './folder-browser.component.html',
  styleUrls: ['./folder-browser.component.scss']
})
export class FolderBrowserComponent implements OnInit {

  FolderKey = 'Folder';
  HomeUrl = '/admin/images';

  folderSet: Set<FolderType>;
  folders: FolderType[] = [];

  folderID: string;
  tree: FolderType[] = [];
  folder: FolderType;
  showRecentFolder = false;

  RecentFolder: FolderType = {
    name: 'Recent Uploads',
    parent: null,
    ID: 'recent'
  };

  itemSet: Set<any>;
  items: any[] = [];
  showItems = false;

  context: string;
  folderContextID: string;

  constructor(
    public db: DbService,
    public route: Router,
    public utils: UtilsService) { }

  ngOnInit() {

    this.db.InitDataStore(this.FolderKey);

    this.folderSet = DataStore.sets[this.FolderKey];

    const Promises: Array<Promise<any>> = [];

    if (this.context && this.folderContextID) {

      let ContextKey = this.context;

      if (this.context.includes(':')) {
        ContextKey = this.context.split(':')[1];
      }

      const QueryPromise = this.db.query(this.FolderKey, '_' + ContextKey, this.folderContextID);

      QueryPromise.then(folders => {
        this.folderSet = folders;
      });

      Promises.push(QueryPromise);

    } else {

      Promises.push(this.db.list(this.FolderKey));

    }

    Promise.all(Promises).then(() => {
      this.setFolder();
    });

    this.route.events.subscribe((val) => {

      if (val instanceof NavigationEnd) {

        this.setFolder();

      }

    });

  }

  navigate(item: FolderType) {

    this.route.navigateByUrl(this.HomeUrl + (item && item.ID ? '/' + item.ID : ''));

  }

  getCurFolderID(): string {

    const bits = this.route.url.split('/');
    const output = bits[bits.length - 1];
    return output === 'images' ? null : output;

  }

  setFolder() {

    const id = this.getCurFolderID();

    this.folder = null;
    this.folders.length = 0;

    for (const folder of this.folderSet) {
      if (folder.parent === id || (!id && !folder.parent)) {
        this.folders.push(folder);
      }
      if (folder.ID === id) {
        this.folder = folder;
      }
    }

    this.utils.sort(this.folders, 'name');

    if (this.showRecentFolder) {

      if (!id) {
        this.folders.unshift(this.RecentFolder);
      } else if (id === 'recent') {
        this.folder = this.RecentFolder;
      }

    }

    this.folderID = id;

    this.tree.length = 0;

    if (id) {
      this.buildTree(this.folder);
    }

    if (this.showItems) {

      this.items.length = 0;

      if (id === 'recent') {

        const MaxItemsShown = 50;

        this.items = Array.from(this.itemSet).reverse();

        if (this.itemSet.size > MaxItemsShown) {
          this.items.length = MaxItemsShown;
        }

      } else {

        const ParsedKey = this.parseKey(this.FolderKey);

        for (const item of this.itemSet) {

          if (item[`_${ParsedKey}`] === id || (!id && !item[`_${ParsedKey}`])) {
            this.items.push(item);
          }
        }

        this.utils.sort(this.items, 'name');

      }

    }

  }

  parseKey(key: string) {
    if (key.includes(':')) {
      return key.split(':')[1];
    }
    return key;
  }

  buildTree(folder: FolderType) {

    if (!folder) {
      return;
    }

    this.tree.unshift(folder);

    if (folder.parent) {
      for (const item of this.folderSet) {
        if (item.ID === folder.parent) {
          this.buildTree(item);
        }
      }
    }

  }

}
