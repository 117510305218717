<app-admin>
    <div class="mat-typography">
        <nav class="crumbs">
            <span>
                <a (click)="navigate(null)">Home</a>
            </span>
            <span *ngFor="let item of tree; let last = last" class="ilb" [hidden]="last && tree[tree.length-1].ID !== 'recent'">
                <mat-icon>chevron_right</mat-icon>
                <a (click)="navigate(item)">{{ item.name }}</a>
            </span>
            <span class="ilb" *ngIf="tree.length && tree[tree.length-1].ID !== 'recent'" [matMenuTriggerFor]="folderOptions">
                <mat-icon>chevron_right</mat-icon>
                <a  (click)="navigate(tree[tree.length-1])" class="ilb">
                    {{ tree[tree.length-1].name }}
                    <mat-icon style="margin: -4px 0;">arrow_drop_down</mat-icon>
                </a>
            </span>
            <mat-menu #folderOptions="matMenu">
                <button mat-menu-item (click)="renameFolder()">
            <app-icon>textbox</app-icon>
            Rename
        </button>
                <button mat-menu-item (click)="newFolder()">
            <mat-icon>create_new_folder</mat-icon>
            New folder
        </button>
                <button mat-menu-item (click)="moveFolder()">
            <app-icon>folder-move</app-icon>
            Move to
        </button>
                <button mat-menu-item (click)="deleteFolder()">
            <mat-icon>delete</mat-icon>
            Delete
        </button>
            </mat-menu>
        </nav>

        <div class="folders">
            <div class="folder" *ngFor="let folder of folders">
                <div class="wrapper ilb" (click)="navigate(folder)" mat-ripple>
                    <mat-icon>folder</mat-icon>
                    <span class="label">{{ folder.name }}</span>
                </div>
            </div>
        </div>

        <div class="images">
            <div class="image item" *ngFor="let image of items" (dblclick)="OpenImgixDialog(image)" (contextmenu)="$event.preventDefault(); openContextMenu($event, image)">
                <div class="wrapper" mat-ripple [ngClass]="{selected: selectedItems.has(image)}" (click)="selectItem(image, $event)">
                    <img *ngIf="!image.uploader" [src]="image.host + image.path + '?fit=crop&w=250&h=200&auto=format' + (image.params || '')" />
                    <div class="progress" *ngIf="image.uploader">
                        <mat-progress-spinner color="primary" mode="determinate" [value]="image.uploader.percent">
                        </mat-progress-spinner>
                    </div>
                    <div class="label ilb">
                        <mat-icon>image</mat-icon>
                        <span>{{ image.name }}</span>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #rightClickContextMenu>
            <div class="ContextMenu mat-elevation-z10">
                <button mat-menu-item [hidden]="this.selectedItems.size > 1" (click)="OpenImgixDialog()">
                    <app-icon>link</app-icon>
                    Get Url
                </button>
                <button mat-menu-item [hidden]="this.selectedItems.size > 1" (click)="renameItem()">
                <app-icon>textbox</app-icon>
                Rename
            </button>
                <button mat-menu-item [hidden]="this.selectedItems.size > 1" (click)="openItem()">
                <mat-icon>open_in_new</mat-icon>
                Open
            </button>
                <button mat-menu-item (click)="moveItem()">
                <app-icon>image-move</app-icon>
                Move to
            </button>
                <button mat-menu-item (click)="deleteItem()">
                <mat-icon>delete</mat-icon>
                Delete
            </button>
            </div>
        </ng-template>

        <button mat-raised-button color="primary" [matMenuTriggerFor]="imageBrowserMenu" class="mat-elevation-z10 pill">
        <mat-icon>add</mat-icon> New
    </button>

        <mat-menu #imageBrowserMenu="matMenu" overlapTrigger="true">
            <button mat-menu-item (click)="upload()">
                <app-icon>image-plus</app-icon>
                Image upload
            </button>
            <button mat-menu-item (click)="unsplash()">
                <app-icon>image-plus</app-icon>
                Browse Unsplash Photos
            </button>
            <button mat-menu-item (click)="newFolder()">
        <app-icon>folder-plus</app-icon>
        Folder
    </button>
        </mat-menu>
    </div>
</app-admin>