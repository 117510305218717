<app-menu></app-menu>
<app-back-header title="Events" back="" theme="charcoal"></app-back-header>
<div class="wrapper">
    <p><i><b>We have many programs running around Australia, see which programs are running close to you!</b></i></p>
   
    <h2>Maker Heals VIC</h2>

    <p><i>10-day Intensive</i></p>
    <p><b>When: </b>November 3 - 12, 2024<br>
    <b>Where:</b> Jumbunna Lodge, Launching Place, Victoria</p>
    <p><b>For more details:</b> Contact Ledua at 0494 033 993</p>

    <h2>Maker Heals SQLD</h2>
        
    <p><i>10-day Intensive</i></p>
    <p><b>When: </b>September 29 - October 8, 2024<br>
    <b>Where:</b> Cedar Grove, South Queensland<br>
    <b>Accommodation:</b> Limited Accommodation but Caravans/Tents Welcome</p>
    <p><b>For more details:</b> Contact Christine at 0412 858 038</p>

    <h2>Maker Heals WA</h2>

    <p><i>10-day Intensive</i></p>
    <p><b>When: </b>September 8 - 17, 2024<br>
    <b>Where:</b> Shalem Health Retreat</p>
    <p><b>For more details:</b> Contact Jo Laing (Manager) at 0412 400 494 for admin inquiries or Noel Mondejar (Health Director) at 0435 313 370 for health-related inquiries</p>

    <p><i>10-day Intensive</i></p>
    <p><b>When: </b>November 3 - 12, 2024<br>
    <b>Where:</b> Shalem Health Retreat</p>
    <p><b>For more details:</b> Contact Jo Laing (Manager) at 0412 400 494 for admin inquiries or Noel Mondejar (Health Director) at 0435 313 370 for health-related inquiries</p>

    <h2>Maker Heals SA</h2>
    <p><i>10-day Intensive</i></p>
    <p><b>When: </b>November 13-23, 2024<br>
    <b>Where:</b> Woodhouse Rymill Centre, 37 Spring Gully Rd, Piccadilly SA 5151<br>
    <b>Cost:</b> Minimum of $600</p>
    <p><b>For more details:</b> Contact Sue Wilson at 0400 640 122 or susanewilson59@gmail.com</p>
</div>

<app-footer></app-footer>