<app-menu></app-menu>
<app-fullscreen-image src="../../../../assets/AdobeStock_292955788.jpg">
    <div class="wrapper">

        <div class="card mat-elevation-z8">

            <h1>Login</h1>

            <form [formGroup]="loginForm" autocomplete="off" novalidate (ngSubmit)="login(loginForm.value)">

                <mat-form-field class="field">
                    <input matInput tabindex="1" formControlName="email" placeholder="Email" type="text">
                    <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error>
                    <mat-error *ngIf="hasError('email', 'email')">Invalid email</mat-error>
                </mat-form-field>

                <mat-form-field class="field">
                    <input matInput tabindex="2" formControlName="password" placeholder="Password" [type]="hidePwd ? 'password' : 'text'">
                    <button type="button" mat-icon-button matSuffix (click)="hidePwd = !hidePwd" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePwd">
                        <mat-icon>{{hidePwd ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="hasError('password', 'required')">Password is required</mat-error>
                    <mat-error *ngIf="hasError('password', 'pattern')">Must be at least six characters with a letter and a number.</mat-error>
                </mat-form-field>

                <mat-form-field class="field">
                    <mat-label>Remember me on this device:</mat-label>
                    <mat-select tabindex="3" formControlName="remember">
                        <mat-option value="10">for 10 minutes</mat-option>
                        <mat-option value="60">for 1 hour</mat-option>
                        <mat-option value="1440">for 1 day</mat-option>
                        <mat-option value="10080">for 1 week</mat-option>
                        <mat-option value="43200">for 1 month</mat-option>
                        <mat-option value="*">Forever (until I logout)</mat-option>
                    </mat-select>
                </mat-form-field>

                <p>
                    <button type="submit" tabindex="3" mat-raised-button color="primary" [disabled]="activity || !loginForm.valid">Login</button>
                </p>

                <div>
                    Don't have an account? <a routerLink="/signup">Sign up</a>
                </div>
                <div>
                    Forgot your password? <a routerLink="/recover-pwd">Recover</a>
                </div>

            </form>

        </div>
    </div>
</app-fullscreen-image>
<app-footer></app-footer>