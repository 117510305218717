import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit {

  settings = '?h=200&w=200&fit=crop&auto=format';

  Images = [
    'https://cdn2.tda.website/beehive/20200611/abide-main-enterance-night22.jpg',
    'https://cdn2.tda.website/beehive/20200611/aerialview-main-retreat.jpg',
    'https://cdn2.tda.website/beehive/20200611/abide-room-1.jpg',
    'https://cdn2.tda.website/beehive/20200611/abide-room-2.jpg',
    'https://cdn2.tda.website/beehive/20200611/abide-room-3.jpg',
    'https://cdn2.tda.website/beehive/20200611/abide-twin-room.jpg',
    'https://cdn2.tda.website/beehive/20200611/abide-twin-room2.jpg',
    'https://cdn2.tda.website/beehive/20200611/acacia-academy.jpg',
    'https://cdn2.tda.website/beehive/20200611/aerialview-whole-site.jpg',
    'https://cdn2.tda.website/beehive/20200611/outdoor-shelter-1.jpg',
    'https://cdn2.tda.website/beehive/20200611/front-door-wide.jpg',
    'https://cdn2.tda.website/beehive/20200611/food-patatoes.jpg',
    'https://cdn2.tda.website/beehive/20200611/food-grated-beetroot-zucchini-carrots-and-cucumber.jpg',
    'https://cdn2.tda.website/beehive/20200611/food-corn.jpg',
  ];

  constructor(
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle( 'Photos - The Maker Heals' );
  }

}
