<app-menu></app-menu>
<app-back-header title="Contact Us" [back]="backLink" theme="charcoal"></app-back-header>
<app-section theme="lightGrey" class="phones">
    <h2>The Maker Heals - Ledua Brooks</h2>
    <p class="ilb" style="font-size: 30px;">
        <app-icon style="width: 30px;height: 30px; margin-right: 20px;">phone</app-icon>
        <span>0490 033 993</span>
    </p>
    <h2>Madison Missions</h2>
    <p class="ilb" style="font-size: 30px;">
        <app-icon style="width: 30px;height: 30px; margin-right: 20px;">phone</app-icon>
        <span>0468 321 559</span>
    </p>
    <h2>Or scroll down to send a message:</h2>
</app-section>
<div id="form"></div>
<app-share></app-share>
<app-subscribe></app-subscribe>
<app-footer></app-footer>