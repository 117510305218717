import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import { AddressType } from 'src/app/services/DataStore';
import { DbService } from 'src/app/services/db.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-account-personal-details',
  templateUrl: './account-personal-details.component.html',
  styleUrls: ['./account-personal-details.component.scss']
})
export class AccountPersonalDetailsComponent implements OnInit {

  addressForm: FormGroup;
  isProcessing = false;
  MyAddresses: AddressType[] = [];
  AddAddress = false;
  Editing = false;
  EditingAdr: AddressType;

  constructor(
    private utils: UtilsService,
    private db: DbService,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {

    this.db.list('Address').then(AddressSet => {
      this.MyAddresses = Array.from(AddressSet);
    });

    this.addressForm = new FormGroup({

      name: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
      instructions: new FormControl('', []),
      address1: new FormControl('', [Validators.required]),
      address2: new FormControl(''),
      suburb: new FormControl('', [Validators.required]),
      postcode: new FormControl('', [Validators.required]),
      state: new FormControl('VIC'),

    });

  }

  async edit(adr: AddressType) {
    this.Editing = true;
    this.EditingAdr = adr;
    this.addressForm.controls.instructions.setValue(adr.instructions);
    this.addressForm.controls.name.setValue(adr.name);
    this.addressForm.controls.mobile.setValue(adr.mobile);
    this.addressForm.controls.address1.setValue(adr.address1);
    this.addressForm.controls.address2.setValue(adr.address2);
    this.addressForm.controls.suburb.setValue(adr.suburb);
    this.addressForm.controls.state.setValue(adr.state);
    this.addressForm.controls.postcode.setValue(adr.postcode);
    this.AddAddress = true;
  }

  async delete(adr: AddressType) {
    if (confirm('Delete address?')) {
      const res = await this.db.delete(adr);
      if (res) {
        this.utils.delete(this.MyAddresses, adr);
        this.snackBar.open('Address deleted.', 'Dismiss', {
          duration: 6000,
        });
      }
    }
  }

  cancel() {
    this.AddAddress = false;
    this.Editing = false;
    this.EditingAdr = null;
    this.addressForm.reset();
  }

  async save() {

    if (!this.addressForm.valid) {
      this.touchForm(this.addressForm);
      this.scrollIfFormHasErrors(this.addressForm);

      this.snackBar.open('Please complete all required fields.', 'Dismiss', {
        duration: 6000,
      });

      return;
    }

    let Adr: AddressType;
    let AdrValue: any;

    AdrValue = this.addressForm.value;

    if (this.Editing) {

      Adr = this.EditingAdr;
      Adr.name = AdrValue.name;
      Adr.mobile = this.utils.normalizeMobile(AdrValue.mobile);
      Adr.instructions = AdrValue.instructions;
      Adr.address1 = AdrValue.address1;
      Adr.address2 = AdrValue.address2;
      Adr.postcode = AdrValue.postcode;
      Adr.state = AdrValue.state;
      Adr.suburb = AdrValue.suburb;

    } else {

      Adr = {
        name: AdrValue.name,
        mobile: this.utils.normalizeMobile(AdrValue.mobile),
        instructions: AdrValue.instructions,
        address1: AdrValue.address1,
        address2: AdrValue.address2,
        postcode: AdrValue.postcode,
        state: AdrValue.state,
        suburb: AdrValue.suburb,
      };

    }

    await this.db.put('Address', Adr).then((AddressObj: AddressType) => {
      if (!this.Editing) {
        this.MyAddresses.push(AddressObj);
      }
    });

    this.snackBar.open('Address saved.', 'Dismiss', {
      duration: 6000,
    });

    this.addressForm.reset();

    this.AddAddress = false;
    this.Editing = false;
    this.EditingAdr = null;

  }



  public hasError = (controlName: string, errorName: string, formName?: string) => {
    formName = formName ? formName : 'registrationForm';
    return this[formName].controls[controlName].hasError(errorName);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid:not(form)');
    this.scrollTo(firstElementWithError);
  }

  async scrollIfFormHasErrors(form: FormGroup): Promise<any> {
    this.scrollToError();
  }

  touchForm(form: FormGroup) {
    // touch all controls, so invalid fields are highlighted.
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

}
