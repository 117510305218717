<app-menu></app-menu>

<app-section theme="grey50">
    <h1>My Account</h1>
</app-section>

<app-section padding="50px" [hidden]="!ActiveRecurringDonations.length">
    <h2>Recurring Donations</h2>
    <div class="recurring" *ngFor="let donation of ActiveRecurringDonations">
        <h3>{{ donation.Amount | currency }}</h3>
        <p>Donation occurs on the {{ donationDayOfMonth(donation) }} of each month.</p>
        <app-button theme="lightGrey" label="Cancel" (click)="cancel(donation)"></app-button>
    </div>
</app-section>

<app-section padding="50px" theme="lightGrey">
    <div class="account-details">
        <div class="detail-box">
            <h2>Personal Details</h2>
            <div *ngIf="myAddresses[0]">
                <div>{{ customer.First + ' ' + customer.Last }}</div>
                <div>{{ myAddresses[0].mobile }}</div>
                <div>{{ myAddresses[0].address1 }}</div>
                <div *ngIf="myAddresses[0].address2">{{ myAddresses[0].address2 }}</div>
                <div>{{ myAddresses[0].suburb + ' ' + myAddresses[0].postcode + ' ' + myAddresses[0].state }}</div>
            </div>
            <app-button theme="primary" label="Edit" routerLink="/my-account/edit-details"></app-button>
        </div>
        <div class="detail-box">
            <h2>Credit/Debit Card</h2>
            <div class="card-preview" *ngFor="let card of customer?.StripeCustomer?.sources.data" style="margin-bottom: 12px;">
                {{ card.brand }}, xxxx-{{ card.last4 }}
            </div>
            <app-button theme="primary" label="Edit" routerLink="/my-account/all-payment-sources"></app-button>
        </div>
        <div class="detail-box">
            <h2>Donations</h2>
            <div *ngFor="let donation of Donations" class="inv-btn" matRipple [routerLink]="'/view-donation/' + donation.ID">
                <span style="font-weight: bold; font-size: 16px;">{{ donation.Amount | currency }}</span> - {{ donation.DonationID }}
            </div>
        </div>
    </div>
</app-section>

<app-footer></app-footer>