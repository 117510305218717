import { Component, OnInit, Inject } from '@angular/core';
import { WINDOW } from 'src/app/services/window.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  constructor(
    @Inject(WINDOW) private window: Window,
  ) { }

  ngOnInit(): void {
  }

  register() {
    this.window.open('https://madisonmissions.typeform.com/to/vX7bE1');
  }

}
