import { Component, ChangeDetectorRef, OnInit, AfterViewInit, Inject } from '@angular/core';
import { DbService } from './services/db.service';
import { AuthService } from './services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { DataStore } from './services/DataStore';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'The Maker Heals';

  onGoingRequests = 0;
  isCheckingAuth = false;
  IsAuthenticated = false;

  constructor(
    private db: DbService,
    private authService: AuthService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
  ) {

    DataStore.auth = authService;

  }

  ngAfterViewInit() {

    this.db.onRequestChange((num) => {
      this.onGoingRequests = num;
      this.changeDetector.detectChanges();
    });

    this.document.getElementById('InitLoader').remove();

  }

  ngOnInit() {

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd && window && !val.url.includes('#')) {
        window.scrollTo(0, 0);
      }
    });

    this.authService.onStateChange(AuthState => {

      this.IsAuthenticated = AuthState;

    });

    if (this.authService.hasSessionValue() && !this.authService.authChecked) {

      this.isCheckingAuth = true;

      this.authService.checkSession().then((response: any) => {

        this.isCheckingAuth = false;

      });

    }
  }

}
