<mat-form-field  mat-dialog-title class="field" style="width: 100%;">
    <input matInput #search="matInput" placeholder="Search Photos" type="text" (input)="searchInput()">
</mat-form-field>
<div mat-dialog-content (scroll)="CheckScroll($event)" class="mat-typography">

    <div class="images">
        <div class="image item" *ngFor="let image of images">
            <div class="wrapper" mat-ripple (click)="selectImage(image)">
                <img [src]="image.url" [ngStyle]="{'background-color': image.color}"/>
                <div class="label ilb">
                    <mat-icon>image</mat-icon>
                    <span>by <a [href]="image.user.links.html" target="_blank">{{ image.user.username }}</a></span>
                </div>
            </div>
        </div>
    </div>

</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Close</button>
</div>