import { Component, OnInit, Input } from '@angular/core';
import { ThemeColor, ThemeColors } from 'src/app/modules/colors';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {

  sidePadding = '20px';

  @Input() width = '1138px';
  @Input() padding = '100px';
  @Input() theme: string;
  color: ThemeColor;

  constructor() { }

  ngOnInit(): void {
    if (this.theme) {
      this.color = ThemeColors[this.theme];
    }
  }

}
