import { AuthService } from './../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

export interface SignupData {
  ID?: string;
  first: string;
  last: string;
  email: string;
  password: string;
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['../login/login.component.scss', './signup.component.scss']
})
export class SignupComponent implements OnInit {

  signupForm: FormGroup;
  hidePwd = true;
  activity = false;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.signupForm = new FormGroup({
      first: new FormControl('', [Validators.required]),
      last: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.pattern(
        /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/
      )]),
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.signupForm.controls[controlName].hasError(errorName);
  }

  signup(data: SignupData) {

    this.activity = true;

    this.authService.signup(data).then((response) => {

      this.activity = false;

      if (response.Success) {

        this.router.navigateByUrl('/account');

      }

    });

  }

}
