<app-menu></app-menu>

<app-panel class="toppanel">
    <img src="https://cdn2.tda.website/beehive/20200610/Red-Gum-Nuts.jpg?h=353&w=500&fit=crop&auto=format" id="gumnuts1">
    <h1><span class="alt-font">T</span>HE MAKE<span class="alt-font2">R</span> HEAL<span class="alt-font">S</span></h1>
    <h2>Find Restoration of Body, Mind and Soul</h2>
    <div class="btns">
        <app-button label="Learn More" routerLink="/info" theme="secondary"></app-button>
    </div>
    <img src="https://cdn2.tda.website/beehive/20200610/Gum-Leaves-Nut-with-Splash.jpg?rot=180&h=353&w=500&fit=crop&auto=format" id="gumnuts12">
</app-panel>

<app-section theme="secondary">
    <div class="wrapper">
        <h1>N<span class="alt-font">o</span>thing <span class="alt-font">L</span>ike it in Australia</h1>
        <p>The only health retreat in Australia that runs on 100% donation basis. That means, no fixed price. Come experience The Maker Heals. The free-will donations of our patrons/guests, help us cover costs and achieve our vision.</p>
    </div>
</app-section>

<app-section>
    <img src="https://cdn2.tda.website/beehive/20210527/Maker-Heals-Appstore-80.jpg?w=1000&fit=crop&auto=format" alt="">
    <p style="text-align: center;">Digital program coming soon!</p>
</app-section>

<app-subscribe></app-subscribe>

<app-fullscreen-image src="https://cdn2.tda.website/beehive/20200610/Pine-Forest.jpg?sat=47&fp-z=1.1&q=36&crop=focalpoint&h=1080&w=1920&fit=crop&blend-color=e6426847&blend-mode=normal&auto=format">
    <app-section id="philosophy">
        <div class="wrapper">
            <h1>Our Phi<span class="alt-font">l</span>osophy</h1>
            <p>Through introducing you to the Maker and the Maker's health laws, we encourage a healthy change of lifestyle and wholistic restoration of body, mind & soul.</p>
            <p>
                <app-button theme="primary" routerLink="/info" label="Learn More"></app-button>
            </p>
            <img src="https://cdn2.tda.website/beehive/20200610/The-Maker-Heals-White-white-red.svg" class="mh-logo">
        </div>
    </app-section>
</app-fullscreen-image>

<app-first-seminar></app-first-seminar>

<app-share></app-share>

<app-footer></app-footer>