<nav>
    <div class="start">
        <a routerLinkActive="active" routerLink="" class="logo" matRipple>
            <img src="https://cdn2.tda.website/beehive/20200610/Hands-White.svg">
        </a>
        <a routerLinkActive="active" routerLink="/apply" class="book" matRipple>Apply to Come</a>
        <a routerLinkActive="active" class="mobile-hide" routerLink="/info" matRipple>Info</a>
        <a routerLinkActive="active" class="mobile-hide" routerLink="/photos" matRipple>Photos</a>
        <a routerLinkActive="active" class="mobile-hide" routerLink="/testimonies" matRipple>Testimonies</a>
        <a routerLinkActive="active" class="mobile-hide" routerLink="/events" matRipple>Events</a>
        <a routerLinkActive="active" class="mobile-hide" routerLink="/support" matRipple>Donate</a>
        <a matRipple [matMenuTriggerFor]="menu" class="mobile-show">
            <mat-icon>keyboard_arrow_down</mat-icon>
        </a>
    </div>
    <div class="space mobile-hide"></div>
    <div class="end mobile-hide">
        <a routerLinkActive="active" routerLink="/team" matRipple>Team</a>
        <a routerLinkActive="active" routerLink="/madison" matRipple>About Madison</a>
        <a routerLinkActive="active" routerLink="/contact" matRipple>Contact</a>
    </div>
</nav>
<mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="/info">More Info</button>
    <button mat-menu-item routerLink="/photos">Photos</button>
    <button mat-menu-item routerLink="/testimonies">Testimonies</button>
    <button mat-menu-item routerLink="/events">Events</button>
    <button mat-menu-item routerLink="/support">Donate</button>    
    <button mat-menu-item routerLink="/team">Team</button>
    <button mat-menu-item routerLink="/madison">About Madison</button>    
    <button mat-menu-item routerLink="/contact">Contact</button>
</mat-menu>