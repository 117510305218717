<app-section>
    <div class="wrap">
        <h1>Next Program</h1>
        <h2>Intensive 10-day Program</h2>
        <!-- or call <a href="tel:+61420746255">0420 746 255</a>  -->
        <p>Please visit the <a routerLink="/apply">application form</a> to find out the dates for the next Maker Heals program.</p>
        <p>This seminar is for anyone who is sick and/or who wishes to improve their health and spirituality and find restoration of mind, body and soul.</p>
        <h2 style="font-size: 24px;">Conditions</h2>
        <ol>
            <li>Participants must be able to stay and attend the full program.</li>
            <li>Participants must be able to go up and down stairs.</li>
            <li>Understand that there are shared showers and bathrooms (with separate stalls, of course).</li>    
        </ol>
        <div class="btns">
            <app-button label="Apply Online" routerLink="/apply" theme="primary"></app-button>
            <app-button label="Learn More" routerLink="/info" theme="secondary"></app-button>
        </div>
    </div>
</app-section>

<app-section>
    <div class="wrap">
        <h1>Digital Program</h1>
        <h2>A health program designed for you</h2>
        <p>Since mid 2020 we have been developing software and content for the digital Maker Heals Program.</p>
        <p>Stay tuned and subscribe to our newsletter for updates.</p>
    </div>
</app-section>