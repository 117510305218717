<app-menu></app-menu>
<div class="wrapper">
    <h1>Testim<span class="alt-font">o</span>nie<span class="alt-font">S</span>.</h1>
    <p>The following testimonies are from "The Maker Heals" in Korea. Whilst in Korean, the sub-titles are English and can give you an insight into the personal experiences of those who have attended the program.</p>
    
    <iframe width="560" height="315" src="https://www.youtube.com/embed/BbsHECPy5Vk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <iframe width="560" height="315" src="https://www.youtube.com/embed/i_NW1Atcgdo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    
    <iframe width="560" height="315" src="https://www.youtube.com/embed/deVZg9bTvXg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    
    <iframe width="560" height="315" src="https://www.youtube.com/embed/2rxpNMTxgyc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <iframe width="560" height="315" src="https://www.youtube.com/embed/tsmHIyJZg8Q" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    
    <iframe width="560" height="315" src="https://www.youtube.com/embed/7NN4Z12oh7A" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    
    <iframe width="560" height="315" src="https://www.youtube.com/embed/2wJ1eY0qZ0Q" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <iframe width="560" height="315" src="https://www.youtube.com/embed/SaYtwDjNH7c" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

</div>
<app-subscribe></app-subscribe>
<app-footer></app-footer>