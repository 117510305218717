import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ElementRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-icon',
  template: '<ng-content></ng-content>',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { 'class': 'mat-icon mat-icon-no-color' }
})
export class IconComponent implements OnInit {

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private http: HttpClient
  ) { }

  Endpoint = 'https://cdn2.tda.website/common/icons/';
  Icon: string;

  ngOnInit() {

    this.Icon = this.elementRef.nativeElement.innerText;

    const Url = this.Endpoint + this.Icon + '.svg';

    // this.elementRef.nativeElement.innerHTML = `<img src="${Url}" class="tuilder-icon" />`;

    this.elementRef.nativeElement.innerHTML = ``;

    this.http.get(Url, {
      responseType: 'text'
    }).subscribe(res => {
      this.elementRef.nativeElement.innerHTML = res;
    });

  }

}
