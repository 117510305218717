<div class="donation-wrapper">
    <p>Choose a Donation Amount</p>

    <div class="amounts">
        <button matRipple matRippleColor="#ffffff40" (click)="amount = p" *ngFor="let p of presets">{{ p | currency: 'AUD' : '$' : '1.0-2' }}</button>
    </div>

    <div class="amount">
        <span>$</span>
        <input type="number" [(ngModel)]="amount">
    </div>

    <mat-radio-group aria-label="Donation Frequency" [(ngModel)]="frequency">
        <div matRipple class="radio-outer" (click)="frequency='monthly';" [ngClass]="{selected: frequency === 'monthly' }">
            <mat-radio-button value="monthly">Monthly recurring donation</mat-radio-button>
        </div>
        <div matRipple class="radio-outer" (click)="frequency='onetime';" [ngClass]="{selected: frequency === 'onetime' }">
            <mat-radio-button value="onetime">One-time donation</mat-radio-button>
        </div>
    </mat-radio-group>

    <p>
        <mat-checkbox [(ngModel)]="roundUp">Round up my donation to {{ getRoundedUp() | currency }} so the processing fees are covered.</mat-checkbox>
    </p>

    <p style="margin: 40px 0 0 0;">Payment Information</p>

    <div class="pay-box">
        <div class="header">PAY BY CREDIT CARD</div >
        <div class="field">
            <stripe-card #stripeCard (catch)="onStripeError($event)" [options]="{hidePostalCode: true}" [(token)]="cardToken" [(invalid)]="invalidError"></stripe-card>
        </div>
        <div class="footer"><app-icon>lock</app-icon> SSL Encrypted and Secure</div>
    </div>

    <form [formGroup]="donateForm" (ngSubmit)="continue()">

        <div class="two-inputs">
            <mat-form-field class="field" appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="first" placeholder="First Name" type="text">
                <mat-error *ngIf="hasError('first', 'required')">First Name is required</mat-error>
            </mat-form-field>

            <mat-form-field class="field" appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="last" placeholder="Last Name" type="text">
                <mat-error *ngIf="hasError('last', 'required')">Last Name is required</mat-error>
            </mat-form-field>
        </div>

        <mat-form-field class="field" appearance="outline">
            <mat-label>Email Address</mat-label>
            <input matInput formControlName="email" placeholder="Email" type="email">
            <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error>
            <mat-error *ngIf="hasError('email', 'email')">Invalid email</mat-error>
        </mat-form-field>

        <mat-form-field class="field" appearance="outline">
            <mat-label>Comments</mat-label>
            <textarea matInput cols="30" formControlName="comments" placeholder="Comment" rows="3"></textarea>
        </mat-form-field>

        <app-button theme="primary" [loading]="isProcessing" (click)="continue();" label="Donate Now"></app-button>

    </form>
</div>