import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DbService } from 'src/app/services/db.service';
import { DonationType } from 'src/app/services/DataStore';
import { AuthService } from 'src/app/services/auth.service';
import { WINDOW } from 'src/app/services/window.service';

@Component({
  selector: 'app-donation-receipt',
  templateUrl: './donation-receipt.component.html',
  styleUrls: ['./donation-receipt.component.scss']
})
export class DonationReceiptComponent implements OnInit, OnDestroy {

  paramsSubscription: Subscription;

  Donation: DonationType;

  constructor(
    private activeRoute: ActivatedRoute,
    private auth: AuthService,
    private db: DbService,
    private router: Router,
    @Inject(WINDOW) private window: Window,
  ) { }

  IsTopAdmin = false;

  ngOnDestroy() {
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.paramsSubscription = this.activeRoute.params.subscribe(routeParams => {

      if (routeParams.id) {

        this.load(routeParams.id);

      }

    });

    this.IsTopAdmin = this.auth.isTopAdmin();
  }

  async load(id: string) {

    this.Donation = await this.db.get('Donation', id);

  }

  async delete() {
    if (confirm('Delete this donation?')) {
      await this.db.delete(this.Donation);
      this.db.snackBar.open('Donation has been deleted.', 'Dismiss');
      this.router.navigateByUrl('my-account');
    }
  }

  print() {
    this.window.print();
  }

}
