import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-back-header',
  templateUrl: './back-header.component.html',
  styleUrls: ['./back-header.component.scss']
})
export class BackHeaderComponent implements OnInit {

  @Input() theme: string;
  @Input() title: string;
  @Input() back: string;
  @Input() padding?: string;

  constructor() { }

  ngOnInit(): void {
    this.padding = this.padding || '60px';
  }

}
