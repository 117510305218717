import { FolderBrowserComponent } from './../folder-browser/folder-browser.component';
import { PromptComponent } from '../../dialogs/prompt/prompt.component';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { TemplatePortal } from '@angular/cdk/portal';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { DataStore, ImageType, FolderType } from '../../services/DataStore';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ViewChild, TemplateRef, ViewContainerRef } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { Subscription, fromEvent } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { SelectFolderComponent } from 'src/app/dialogs/select-folder/select-folder.component';

@Component({
  selector: 'app-item-browser',
  template: '',
  styleUrls: ['./folder-item-browser.component.scss']
})
export class FolderItemBrowserComponent extends FolderBrowserComponent implements OnInit {

  ItemKey = 'Image';

  currentItem: any;
  selectedItems: Set<any> = new Set();
  lastClickedItem: any;

  showItems = true;

  @ViewChild('rightClickContextMenu', { static: true }) rightClickContextMenu: TemplateRef<any>;

  overlayRef: OverlayRef | null;
  ContextMenuSub: Subscription;
  extendDeleteItem: (item: any) => void;

  itemContextID: string;

  constructor(
    public dialog: MatDialog,
    public db: DbService,
    public route: Router,
    public utils: UtilsService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef
  ) {
    super(db, route, utils);
  }

  ngOnInit() {

    super.ngOnInit();

    this.db.InitDataStore(this.ItemKey);

    this.itemSet = DataStore.sets[this.ItemKey];

    const Promises: Array<Promise<any>> = [];

    if (this.context && this.folderContextID) {

      let ContextKey = this.context;

      if (this.context.includes(':')) {
        ContextKey = this.context.split(':')[1];
      }

      const QueryPromise = this.db.query(this.ItemKey, '_' + ContextKey, this.itemContextID);

      QueryPromise.then(items => {
        this.itemSet = items;
      });

      Promises.push(QueryPromise);

    } else {

      Promises.push(this.db.listAll(this.ItemKey));

    }

    Promise.all(Promises).then(() => {
      this.setFolder();
    });

  }

  selectItem(item: any, event: MouseEvent) {

    const ShiftKey = event.shiftKey;
    const Existing = this.selectedItems.has(item);
    const Size = this.selectedItems.size;

    if (ShiftKey && Size === 1) {

      let OtherImage: ImageType;

      for (const selectedItem of this.selectedItems) {
        OtherImage = selectedItem;
      }

      const IndexStart = this.items.indexOf(OtherImage);
      const IndexEnd = this.items.indexOf(item);

      if (IndexStart > IndexEnd) {
        for (let i = IndexStart - 1; i >= IndexEnd; i--) {
          this.selectedItems.add(this.items[i]);
        }
      } else {
        for (let i = IndexStart + 1; i <= IndexEnd; i++) {
          this.selectedItems.add(this.items[i]);
        }
      }

    } else if (ShiftKey) {
      if (!this.selectedItems.has(item)) {

        this.selectedItems.add(item);
        this.lastClickedItem = item;

      } else {

        this.selectedItems.delete(item);
        this.lastClickedItem = null;

      }
    } else {
      this.selectedItems = new Set();
      if (!Existing || Size > 1) {
        this.selectedItems.add(item);
      }
    }

  }

  openContextMenu({ x, y }: MouseEvent, item: any) {
    this.closeContextMenu();

    if (!this.selectedItems.has(item)) {
      this.selectedItems = new Set();
      this.selectedItems.add(item);
    }

    this.lastClickedItem = item;
    this.currentItem = item;

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.rightClickContextMenu, this.viewContainerRef, {
      $implicit: item
    }));

    this.ContextMenuSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeContextMenu());

  }

  closeContextMenu() {
    if (this.ContextMenuSub) {
      this.ContextMenuSub.unsubscribe()
    }
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  newFolder() {

    const dialogRef = this.dialog.open(PromptComponent, {
      width: '350px',
      data: { label: 'New folder name:' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        const NewObj = {
          name: result,
          parent: this.folderID || null
        } as FolderType;

        if (this.folderContextID) {

          const ParsedKey = this.parseKey(this.context);

          NewObj['_' + ParsedKey] = this.folderContextID;

        }

        this.db.put(this.FolderKey, NewObj).then((folder: FolderType) => {
          this.folderSet.add(folder);
          this.folders.push(folder);
          this.utils.sort(this.folders, 'name');
        });

      }
    });

  }

  deleteFolder() {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Delete folder, "' + this.folder.name + '"?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        const Folder = this.folder;

        this.deleteFolderCascade(Folder).then(() => {

          this.folderSet.delete(Folder);
          this.navigate(null);

          this.db.snackBar.open('Folder deleted', 'Dismiss', {
            duration: 6000
          });

        });


      }
    });
  }

  renameFolder() {

    const dialogRef = this.dialog.open(PromptComponent, {
      width: '350px',
      data: {
        label: 'Rename folder:',
        value: this.folder.name
      }
    });

    dialogRef.afterClosed().subscribe(newName => {
      if (newName) {
        this.folder.name = newName;
        this.utils.sort(this.folders, 'name');

      }
    });

  }

  renameItem() {

    this.closeContextMenu();

    const dialogRef = this.dialog.open(PromptComponent, {
      width: '350px',
      data: {
        label: `Rename ${this.ItemKey}:`,
        value: this.lastClickedItem.name
      }
    });

    dialogRef.afterClosed().subscribe(newName => {
      if (newName) {
        this.lastClickedItem.name = newName;
        this.utils.sort(this.items, 'name');
        this.db.put(this.lastClickedItem);
      }
    });

  }

  moveFolder() {

    const dialogRef = this.dialog.open(SelectFolderComponent, {
      width: '850px',
      height: '98vh',
      data: {
        title: `Move "${this.folder.name}"`,
        restrict: this.folder,
        folders: this.folderSet,
        FolderKey: this.FolderKey,
        context: this.context,
        folderContextID: this.folderContextID
      }
    });

    dialogRef.afterClosed().subscribe(response => {

      if (response) {

        this.folder.parent = response === 'HOME' ? null : response.ID;

        this.setFolder();

        this.db.snackBar.open('Folder moved', 'Dismiss', {
          duration: 6000
        });

        this.db.put(this.folder);

      }

    });

  }

  moveItem() {

    this.closeContextMenu();

    const NumberOfItemsToMove = this.selectedItems.size;
    let Title = '';

    if (NumberOfItemsToMove === 1) {
      Title = `Move "${this.lastClickedItem.name || this.ItemKey}"`;
    } else {
      Title = `Move ${NumberOfItemsToMove} ${this.ItemKey}${NumberOfItemsToMove !== 1 ? 's' : ''}`;
    }

    const dialogRef = this.dialog.open(SelectFolderComponent, {
      width: '850px',
      height: '98vh',
      data: {
        title: Title,
        folders: this.folderSet,
        FolderKey: this.FolderKey,
        context: this.context,
        folderContextID: this.folderContextID
      }
    });

    dialogRef.afterClosed().subscribe(response => {

      if (response) {

        const ParsedKey = this.parseKey(this.FolderKey);

        for (const item of this.selectedItems) {
          item['_' + ParsedKey] = response === 'HOME' ? null : response.ID;
          this.db.put(item);
        }

        this.selectedItems = new Set();
        this.lastClickedItem = null;

        this.setFolder();

        this.db.snackBar.open(this.ItemKey + '(s) moved', 'Dismiss', {
          duration: 6000
        });
      }

    });

  }

  deleteFolderCascade(folder) {

    const ID = folder.ID;

    return new Promise((resolve) => {

      for (const item of this.itemSet) {
        if (item['_' + this.FolderKey] === ID) {
          this.deleteItem([item], true);
        }
      }

      this.db.delete(folder).then(() => {

        const promises = [];

        for (const item of this.folderSet) {
          if (item.parent === ID) {
            promises.push(this.deleteFolderCascade(item));
          }
        }

        if (promises.length) {
          Promise.all(promises).then(() => {
            resolve();
          });
        } else {
          resolve();
        }

      });

    });

  }

  deleteItem(items?: any[], quiet?: boolean) {

    let ItemsToDelete: Set<any> = new Set();

    if (items) {
      for (const item of items) {
        ItemsToDelete.add(item);
      }
    } else {
      ItemsToDelete = this.selectedItems;
    }

    this.closeContextMenu();

    const NumberOfItemsToMove = ItemsToDelete.size;
    let Title = '';

    if (NumberOfItemsToMove === 1) {
      const firstItem = ItemsToDelete.values().next().value;
      Title = `Delete "${firstItem.name}"?`;
    } else {
      Title = `Delete ${NumberOfItemsToMove} ${this.ItemKey}${NumberOfItemsToMove !== 1 ? 's' : ''}?`;
    }

    if (quiet) {

      this.finishDeleteItem(ItemsToDelete, Title);

    } else {

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: Title
      });
      dialogRef.afterClosed().subscribe(result => {

        if (result) {

          this.finishDeleteItem(ItemsToDelete, Title);

        }

      });

    }

  }

  finishDeleteItem(ItemsToDelete: Set<any>, Title: string) {

    this.db.snackBar.open(Title.replace('Delete', 'Deleted').replace('?', '.'), 'Dismiss', {
      duration: 6000
    });

    for (const item of ItemsToDelete) {

      if (this.extendDeleteItem) {
        this.extendDeleteItem(item);
      }

      this.db.delete(item);

      this.itemSet.delete(item);

    }

    this.selectedItems = new Set();
    this.lastClickedItem = null;
    this.setFolder();

  }

}

