import { ImgixHepler } from '../../helpers/ImgixHelper';
import { HttpClient } from '@angular/common/http';
import { ImageType, FolderType } from './../../services/DataStore';
import { DbService } from 'src/app/services/db.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, Inject, OnInit, HostListener } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

interface UnsplashUser {
  id: string;
  updated_at: string;
  username: string;
  name: string;
  first_name: string;
  last_name: string;
  twitter_username: string;
  portfolio_url: string;
  bio: string;
  location: string;
  links: {
    self: string;
    html: string;
    photos: string;
    likes: string;
    portfolio: string;
    following: string;
    followers: string;
  };
  profile_image: {
    small: string;
    medium: string;
    large: string;
  };
  instagram_username: string;
  total_collections: number;
  total_likes: number;
  total_photos: number;
  accepted_tos: boolean;
}

interface UnsplashImage {
  id: string;
  created_at: string;
  updated_at: string;
  width: number;
  height: number;
  color: string;
  description: string;
  alt_description: string;
  urls: {
    raw: string;
    full: string;
    regular: string;
    small: string;
    thumb: string;
  };
  links: {
    self: string;
    html: string;
    download: string;
    download_location: string;
  };
  categories: any[];
  likes: number;
  liked_by_user: boolean;
  current_user_collections: any[];
  user: UnsplashUser;
}

interface UnsplashSearch {
  total: number;
  total_pages: number;
  results: UnsplashImage[];
}

interface UnsplashOutputImage {
  url: string;
  color: string;
  unsplash: UnsplashImage;
  user: UnsplashUser;
}

@Component({
  selector: 'app-unsplash-picker',
  templateUrl: './unsplash-picker.component.html',
  styleUrls: [
    '../prompt/prompt.component.scss',
    '../../components/folder-browser/folder-browser.component.scss',
    './unsplash-picker.component.scss'
  ]
})
export class UnsplashPickerComponent implements OnInit {

  endpoint = 'https://api-v3.tuilder.com/v3/UnsplashBrowser';
  defaultKeyword = 'nature';

  images: UnsplashOutputImage[] = [];

  isLoading = false;

  currentPage = 1;
  totalPages = 1;
  keyword: string;

  searchInput = this.utils.debounce((keywords: string) => {

    if (keywords) {
      this.search(keywords);
    } else {
      this.search(this.defaultKeyword);
    }

  }, 500);

  constructor(
    public dialog: MatDialog,
    public db: DbService,
    public utils: UtilsService,
    public http: HttpClient,
    public dialogRef: MatDialogRef<UnsplashPickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  @HostListener('scroll', ['$event'])
  CheckScroll(event: Event) {

    const div = event.target as HTMLDivElement;

    // If scroll is 250px from the bottom
    if (div.scrollTop + 250 > div.scrollHeight - div.clientHeight) {
      // Load more images
      this.loadmore();
    }

  }

  ngOnInit() {

    this.search(this.defaultKeyword);

  }

  search(keyword: string) {

    this.images.length = 0;

    this.get(keyword, 1);

  }

  get(keyword: string, pageNum: number) {

    this.keyword = keyword;

    this.currentPage = pageNum;

    const Imgix = new ImgixHepler();

    const ReqUrl = this.endpoint + '?search=' + keyword + '&page=' + pageNum;

    const promise = this.db.getReq(ReqUrl);

    promise.then((res: UnsplashSearch) => {

      this.totalPages = res.total_pages;

      for (const img of res.results) {
        Imgix.parse(img.urls.regular);
        Imgix.w(250);
        const url = Imgix.output();

        this.images.push({
          url,
          color: img.color,
          unsplash: img,
          user: img.user
        });
      }

    });

    return promise;

  }

  async selectImage(image: UnsplashOutputImage) {

    const Imgix = new ImgixHepler();
    Imgix.parse(image.url);

    const Params: string[] = [];

    Object.keys(Imgix.image.params).forEach(key => {
      if (!['crop', 'fit', 'w'].includes(key)) {
        const val = Imgix.image.params[key];
        Params.push(key + '=' + val);
      }
    });

    const Fldrs = Array.from(await this.db.listAll('Folder'));
    let unSplashFolder = this.utils.find(Fldrs, 'name', 'Unsplash Photos') as FolderType;

    if (!unSplashFolder) {
      unSplashFolder = {
        name: 'Unsplash Photos',
        parent: null,
      };
      await this.db.put('Folder', unSplashFolder);
    }

    const NewImage: ImageType = {
      ID: this.utils.uid(),
      name: image.unsplash.description,
      _Folder: unSplashFolder.ID,
      height: image.unsplash.height,
      width: image.unsplash.width,
      path: Imgix.url.pathname.substr(1), // Remove first slash
      host: 'https://' + Imgix.url.hostname + '/',
      __typename: 'Image',
      unsplash: true,
      params: Params.join('&')
    };

    this.db.put(NewImage);

    this.dialogRef.close(NewImage);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  loadmore() {
    if (!this.isLoading && this.totalPages > this.currentPage) {
      this.isLoading = true;
      this.get(this.keyword, this.currentPage + 1).then(() => {
        this.isLoading = false;
      });
    }
  }

}
