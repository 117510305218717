import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  IsAuthenticated = false;

  onAuthChange = async AuthState => {
    this.IsAuthenticated = AuthState;
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private db: DbService,
  ) { }

  ngOnInit(): void {
    this.authService.onStateChange(this.onAuthChange);
  }

  ngOnDestroy() {
    this.authService.removeStateChangeCallback(this.onAuthChange);
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('');
  }

}
