<app-menu></app-menu>
<app-fullscreen-image src="../../../../assets/AdobeStock_292955788.jpg">
    <div class="wrapper">

        <div class="card mat-elevation-z8">

            <h1>Reset Your Password</h1>

            <form [formGroup]="resetForm" autocomplete="off" novalidate (ngSubmit)="reset(resetForm.value)">

                <mat-form-field class="field">
                    <input matInput tabindex="2" formControlName="password1" placeholder="New Password" [type]="hidePwd1 ? 'password' : 'text'">
                    <button type="button" mat-icon-button matSuffix (click)="hidePwd1 = !hidePwd1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePwd1">
                        <mat-icon>{{hidePwd1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="hasError('password1', 'required')">Password is required</mat-error>
                    <mat-error *ngIf="hasError('password1', 'pattern')">Must be at least six characters with a letter and a number.</mat-error>
                </mat-form-field>

                <mat-form-field class="field">
                    <input matInput tabindex="2" formControlName="password2" placeholder="Confirm New Password" [type]="hidePwd2 ? 'password' : 'text'">
                    <button type="button" mat-icon-button matSuffix (click)="hidePwd2 = !hidePwd2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePwd2">
                        <mat-icon>{{hidePwd2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="hasError('password2', 'required')">Password is required</mat-error>
                    <mat-error *ngIf="hasError('password2', 'pattern')">Must be at least six characters with a letter and a number.</mat-error>
                </mat-form-field>

                <p>
                    <button type="submit" tabindex="3" mat-raised-button color="primary" [disabled]="activity || !resetForm.valid">Save New Password</button>
                </p>

                <div>
                    <a routerLink="/login">Login</a>
                </div>

            </form>

        </div>
    </div>
</app-fullscreen-image>
<app-footer></app-footer>