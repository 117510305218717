<app-back-header title="My Payment Sources" theme="grey50" back="/my-account"></app-back-header>

<div style="max-width: 1100px; padding: 5%;">
    <table cellspacing="0">
        <thead>
            <th>Card Brand</th>
            <th>Last 4</th>
            <th>Expires</th>
            <th></th>
        </thead>
        <tr *ngFor="let card of Person?.StripeCustomer?.sources.data">
            <td>{{ card.brand }}</td>
            <td>{{ card.last4 }}</td>
            <td>{{ card.exp_month + '/' + card.exp_year }}</td>
            <td class="right">
                <app-button theme="grey50" (click)="remove(card)" [loading]="cardProcessing[card.id]" label="Remove"></app-button>
            </td>
        </tr>
    </table>

    <div class="payment-box" [hidden]="!AddPaymentSource">

        <img src="/assets/Visa-Mastercard-Logo.svg" width="120" height="60" alt="">

        <h2 style="margin-bottom: 30px;">Payment Details</h2>

        <stripe-card #stripeCard [options]="{hidePostalCode: true}" [(token)]="cardToken"></stripe-card>

        <div class="btns" style="padding-top: 30px;">
            <app-button theme="primary" (click)="save()" label="Save" [loading]="isProcessing"></app-button> <br>
            <app-button (click)="AddPaymentSource = !AddPaymentSource" [hidden]="!AddPaymentSource" theme="grey50" label="Cancel"></app-button>
        </div>

    </div>
    <div class="btns" [hidden]="AddPaymentSource">
        <app-button theme="primary" (click)="AddPaymentSource = true" [loading]="isProcessing" label="Add Payment Source"></app-button>
    </div>
</div>