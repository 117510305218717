import { FolderBrowserComponent } from './../../components/folder-browser/folder-browser.component';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewContainerRef } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { FolderType } from 'src/app/services/DataStore';
import { Overlay } from '@angular/cdk/overlay';

@Component({
  selector: 'app-select-folder',
  templateUrl: './select-folder.component.html',
  styleUrls: [
    '../prompt/prompt.component.scss',
    '../../components/folder-browser/folder-browser.component.scss',
    './select-folder.component.scss'
  ]
})
export class SelectFolderComponent extends FolderBrowserComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public db: DbService,
    public route: Router,
    public utils: UtilsService,
    public dialogRef: MatDialogRef<SelectFolderComponent>,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      restrict: FolderType,
      folders: Set<FolderType>,
      FolderKey: string,
      context: string
      folderContextID: string
    }) {
      super(db, route, utils);
      this.FolderKey = this.data.FolderKey;
      this.context = this.data.context;
      this.folderContextID = this.data.folderContextID;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  navigate(item: FolderType) {
    this.folder = item;
    this.setFolder();
  }

  getCurFolderID(): string {
    return this.folder && this.folder.ID || null;
  }

  confirm() {    
    this.dialogRef.close(this.folder || 'HOME');
  }

}
