import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { FolderItemBrowserComponent } from 'src/app/components/folder-item-browser/folder-item-browser.component';
import { ImageType, UploaderProgress } from 'src/app/services/DataStore';
import { UploaderService } from 'src/app/services/uploader.service';
import { Overlay } from '@angular/cdk/overlay';
import { ImgixEditorComponent } from 'src/app/dialogs/imgix-editor/imgix-editor.component';
import { UnsplashPickerComponent } from 'src/app/dialogs/unsplash-picker/unsplash-picker.component';

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: [
    '../../../components/folder-browser/folder-browser.component.scss',
    './images.component.scss'
  ]
})
export class ImagesComponent extends FolderItemBrowserComponent implements OnInit {

  currentItem: ImageType;

  selectedItems: Set<ImageType> = new Set();
  lastClickedItem: ImageType;

  constructor(
    public dialog: MatDialog,
    public db: DbService,
    public route: Router,
    public utils: UtilsService,
    private uploader: UploaderService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef
  ) {
    super(dialog, db, route, utils, overlay, viewContainerRef);
    this.showItems = true;
    this.showRecentFolder = true;
  }

  items: ImageType[] = [];

  openItem() {
    window.open(this.lastClickedItem.host + this.lastClickedItem.path);
    this.closeContextMenu();
  }

  extendDeleteItem = (item: ImageType) => {

    if (!item.unsplash) {
      this.uploader.deleteFile(item.path);
    }

  }

  getImageDimensions(file: File): Promise<{ width: number, height: number }> {

    const URL = window.URL || window['webkitURL'];

    return new Promise(resolve => {

      const img = new Image();

      img.src = URL.createObjectURL(file);

      img.onload = () => {

        const width = img.naturalWidth;
        const height = img.naturalHeight;

        URL.revokeObjectURL(img.src);

        resolve({
          width, height
        });

      };

    });

  }

  OpenImgixDialog(image?: ImageType) {

    image = image || this.lastClickedItem;

    this.closeContextMenu();

    this.route.navigateByUrl('/admin/edit-image/' + image.ID + '?back=' + this.route.url);

  }

  unsplash() {

    const dialogRef = this.dialog.open(UnsplashPickerComponent, {
      width: '1200px',
      height: '98vh',
      data: {
        title: `Select an Image`
      }
    });

    dialogRef.afterClosed().subscribe((img: ImageType) => {

      if (img) {

        this.OpenImgixDialog(img);

      }

    });
  }

  upload() {

    const el = document.createElement('input');
    el.type = 'file';
    el.multiple = true;
    el.onchange = (e: HTMLInputEvent) => {
      for (const file of Array.from(e.target.files)) {

        const NewImage: ImageType = {
          ID: this.utils.uid(),
          name: file.name,
          _Folder: this.folder && this.folder.ID || null,
          height: 0,
          width: 0,
          path: null,
          host: 'https://cdn2.tda.website/',
          __typename: 'Image',
        };

        const Progress: UploaderProgress = {
          percent: 0,
          done: false,
        };

        NewImage.uploader = Progress;

        const ImageDimensions = this.getImageDimensions(file);

        this.uploader.upload(file, Progress).then((fileUrl: string) => {

          NewImage.path = fileUrl;

          ImageDimensions.then(dimensions => {

            NewImage.height = dimensions.height;
            NewImage.width = dimensions.width;

            delete NewImage.uploader;

            this.db.put(NewImage);

          });
        });

        this.items.push(NewImage);
        this.utils.sort(this.items, 'name');

      }
    };

    el.click();

  }

}
