import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './screens/home/home.component';
import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material-modules';
import { ButtonComponent } from './components/button/button.component';
import { SectionComponent } from './components/section/section.component';
import { BackHeaderComponent } from './components/back-header/back-header.component';
import { FullscreenImageComponent } from './components/fullscreen-image/fullscreen-image.component';
import { LoginComponent } from './screens/auth/login/login.component';
import { SignupComponent } from './screens/auth/signup/signup.component';
import { Page404Component } from './screens/page404/page404.component';
import { RecoverPwdComponent } from './screens/auth/recover-pwd/recover-pwd.component';
import { ResetPwdComponent } from './screens/auth/reset-pwd/reset-pwd.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminHomeComponent } from './screens/admin/admin-home/admin-home.component';
import { AdminAuthGuardService, AuthGuardService } from './services/auth-guard.service';
import { AdminSideMenuComponent } from './components/admin/admin-side-menu/admin-side-menu.component';
import { AdminComponent } from './components/admin/admin/admin.component';
import { ImagesComponent } from './screens/admin/images/images.component';
import { FolderBrowserComponent } from './components/folder-browser/folder-browser.component';
import { FolderItemBrowserComponent } from './components/folder-item-browser/folder-item-browser.component';
import { ConfirmSaveComponent } from './dialogs/confirm-save/confirm-save.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { PromptComponent } from './dialogs/prompt/prompt.component';
import { SelectFolderComponent } from './dialogs/select-folder/select-folder.component';
import { SelectImageComponent } from './dialogs/select-image/select-image.component';
import { UnsplashPickerComponent } from './dialogs/unsplash-picker/unsplash-picker.component';
import { IconComponent } from './components/icon/icon.component';
import { ImgixEditorComponent } from './dialogs/imgix-editor/imgix-editor.component';
import { ContactUsComponent } from './screens/contact-us/contact-us.component';
import { PanelComponent } from './panel/panel.component';
import { WINDOW_PROVIDERS } from './services/window.service';
import { RecipePreviewsComponent } from './components/recipe-previews/recipe-previews.component';
import { SupportComponent } from './screens/support/support.component';
import { ShareComponent } from './components/share/share.component';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { RegistrationComponent } from './screens/registration/registration.component';
import { PhilosophyComponent } from './screens/philosophy/philosophy.component';
import { MadisonComponent } from './screens/madison/madison.component';
import { FaqComponent } from './screens/faq/faq.component';
import { TeamComponent } from './screens/team/team.component';
import { PrivacyPolicyComponent } from './screens/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './screens/terms-of-service/terms-of-service.component';
import { EventsComponent } from './screens/events/events.component';
import { FirstSeminarComponent } from './components/first-seminar/first-seminar.component';
import { PhotosComponent } from './screens/photos/photos.component';
import { TestimoniesComponent } from './screens/testimonies/testimonies.component';
import { DonateComponent } from './components/donate/donate.component';
import { StripeModule } from 'stripe-angular';
import { DonationReceiptComponent } from './screens/donation-receipt/donation-receipt.component';
import { AccountPaymentSourcesComponent } from './components/account/account-payment-sources/account-payment-sources.component';
import { AccountPersonalDetailsComponent } from './components/account/account-personal-details/account-personal-details.component';
import { ShowAddressComponent } from './components/show-address/show-address.component';
import { AccountComponent } from './components/account/account.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    FooterComponent,
    ButtonComponent,
    SectionComponent,
    BackHeaderComponent,
    FullscreenImageComponent,
    LoginComponent,
    SignupComponent,
    Page404Component,
    RecoverPwdComponent,
    ResetPwdComponent,
    AdminHomeComponent,
    AdminSideMenuComponent,
    AdminComponent,
    ImagesComponent,
    FolderBrowserComponent,
    FolderItemBrowserComponent,
    ConfirmSaveComponent,
    ConfirmationDialogComponent,
    PromptComponent,
    SelectFolderComponent,
    SelectImageComponent,
    UnsplashPickerComponent,
    IconComponent,
    ImgixEditorComponent,
    ContactUsComponent,
    PanelComponent,
    RecipePreviewsComponent,
    SupportComponent,
    ShareComponent,
    SubscribeComponent,
    RegistrationComponent,
    PhilosophyComponent,
    MadisonComponent,
    FaqComponent,
    TeamComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    EventsComponent,
    FirstSeminarComponent,
    PhotosComponent,
    TestimoniesComponent,
    DonateComponent,
    DonationReceiptComponent,
    AccountComponent,
    AccountPaymentSourcesComponent,
    AccountPersonalDetailsComponent,
    ShowAddressComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    ColorPickerModule,
    // StripeModule.forRoot('pk_test_fH1TQydI71pUImt3hDr5bGMX00as18FK4P'),
    StripeModule.forRoot('pk_live_TevlenGYgSSw58e6AXbrbV8800iolj2NAo'),
  ],
  entryComponents: [
    ConfirmSaveComponent,
    ConfirmationDialogComponent,
    PromptComponent,
    SelectFolderComponent,
    SelectImageComponent,
    UnsplashPickerComponent,
  ],
  providers: [ AdminAuthGuardService, AuthGuardService, WINDOW_PROVIDERS ],
  bootstrap: [AppComponent]
})
export class AppModule { }
