import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DbService } from 'src/app/services/db.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reset-pwd',
  templateUrl: './reset-pwd.component.html',
  styleUrls: [
    '../login/login.component.scss',
    './reset-pwd.component.scss',
  ]
})
export class ResetPwdComponent implements OnInit {

  activity = false;
  resetForm: FormGroup;
  hidePwd1 = true;
  hidePwd2 = true;

  constructor(
    private router: Router,
    private db: DbService,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {

    this.resetForm = new FormGroup({

      password1: new FormControl('', [Validators.required, Validators.pattern(
        /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/
      )]),

      password2: new FormControl('', [Validators.required, Validators.pattern(
        /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/
      )]),

    });

  }

  public hasError = (controlName: string, errorName: string) => {
    return this.resetForm.controls[controlName].hasError(errorName);
  }

  async reset(data: any) {

    if (data.password1 !== data.password2) {
      alert('The two passwords do not match. Please double-check;');
      return;
    }

    this.activity = true;

    const key: string = this.route.snapshot.queryParamMap.get('key');

    const password = data.password1;

    const res = await this.db.q('auth:resetPwd', { password, key, });

    if (res.Success) {
      this.snackBar.open('Your password has been reset. Please login.', 'Dismiss', {
        duration: 6000,
      });
      this.router.navigateByUrl('/login');
    } else {
      this.snackBar.open(res.Message, 'Dismiss', {
        duration: 6000,
      });
    }

    this.activity = false;
  }

}
