<app-menu></app-menu>
<app-back-header title="Meet the Team" back="" theme="charcoal"></app-back-header>
<div class="wrapper">
    <p>The Maker Heals team is led by Ledua Brooks and supported by an amazing group of individuals passionate about health and faith. <br> Ledua worked with the late Joy Cho who was trained at Bethel Sanitarium in South Korea. Bethel Sanitarium has helped people spiritually and brought healing for more than 30 years.</p>

    <p>
        Ledua holds a Bachelor of Education (Secondary) from Avondale College, majoring in Home Economics & Design Technology. She has been a teacher for over 20 years. Previous to teaching she was a registered nurse in New Zealand.
    </p>

    <!-- <div class="imgs" style="max-width: 500px;">
        <img src="https://cdn2.tda.website/beehive/20240222/EMgqLzDh-Ledua-Brooks.jpg?mask=corners&corner-radius=150,150,150,150&crop=faces&h=250&w=250&fit=facearea&facepad=2.1&con=27&auto=format" alt="Joy Cho">
    </div> -->

    <p>&nbsp;</p>
    <p>Maker Heals & Madison is a diverse team of individuals united by their faith, care for people, and passion for health.</p>

</div>
<app-first-seminar></app-first-seminar>
<app-share></app-share>
<app-subscribe></app-subscribe>
<app-footer></app-footer>