import { ImageType, FolderType } from './../../services/DataStore';
import { FolderItemBrowserComponent } from '../../components/folder-item-browser/folder-item-browser.component';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewContainerRef } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { Overlay } from '@angular/cdk/overlay';

@Component({
  selector: 'app-select-image',
  templateUrl: './select-image.component.html',
  styleUrls: [
    '../prompt/prompt.component.scss',
    '../../components/folder-browser/folder-browser.component.scss',
    './select-image.component.scss'
  ]
})
export class SelectImageComponent extends FolderItemBrowserComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public db: DbService,
    public route: Router,
    public utils: UtilsService,
    public dialogRef: MatDialogRef<SelectImageComponent>,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      restrict: FolderType,
      folders: Set<FolderType>,
    }) {
    super(dialog, db, route, utils, overlay, viewContainerRef);
    this.showRecentFolder = true;
  }

  items: ImageType[] = [];

  selectImage(image: ImageType) {
    this.dialogRef.close(image);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  navigate(item: FolderType) {
    this.folder = item;
    this.setFolder();
  }

  ngOnInit() {
      super.ngOnInit();
      this.navigate(this.RecentFolder);
  }

  getCurFolderID(): string {
    return this.folder && this.folder.ID || null;
  }

  confirm() {
    this.dialogRef.close(this.folder || 'HOME');
  }

}
