
export interface ThemeColor {
    hex: string;
    text: string;
}

export const ThemeColors = {
    red: {
        hex: 'red',
        text: '#ffffff',
    },
    primary: {
        hex: '#de4336',
        text: '#ffffff',
    },
    secondary: {
        hex: '#dbefde',
        text: '#222',
    },
    primaryDark: {
        hex: '#A8372D',
        text: '#ffffff',
    },
    complimentary: {
        hex: '#53875B',
        text: '#ffffff',
    },
    compDark: {
        hex: '#065C2B',
        text: '#ffffff',
    },
    DarkRed: {
        hex: '#c90e34',
        text: '#ffffff',
    },
    charcoal: {
        hex: '#222427',
        text: '#ffffff',
    },
    grey50: {
        hex: '#555555',
        text: '#ffffff',
    },
    grey30: {
        hex: '#333333',
        text: '#ffffff',
    },
    grey70: {
        hex: '#707070',
        text: '#ffffff',
    },
    white: {
        hex: '#FFFFFF',
        text: '#00000',
    },
    lightGrey: {
        hex: '#f7f7f7',
        text: '#222222',
    },
    whitePrimary: {
        hex: '#FFFFFF',
        text: '#DA1376',
    },
    black: {
        hex: '#000000',
        text: '#ffffff',
    },
};
