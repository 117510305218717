import { Component, OnInit } from '@angular/core';
import { ImageType, ImageSettingFavType } from 'src/app/services/DataStore';
import { DbService } from 'src/app/services/db.service';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { PromptComponent } from '../prompt/prompt.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { TestObject } from 'protractor/built/driverProviders';

interface SettingOption {
  value: any;
  label: string;
}

export interface ImgixOption {
  option: string;
  settings: ImgixOptionSetting[];
}

export interface ImgixOptionSetting {
  val: string;
  label: string;
  tooltip: string;
  type: string;
  default?: any;
  range?: [number, number];
  step?: number;
  options?: SettingOption[];
  debug?: boolean;
}

@Component({
  selector: 'app-imgix-editor',
  templateUrl: './imgix-editor.component.html',
  styleUrls: ['./imgix-editor.component.scss']
})
export class ImgixEditorComponent implements OnInit {

  image: ImageType;

  favs: ImageSettingFavType[] = [];
  lastClickedFav: ImageSettingFavType;

  types: string[] = [];

  ImgixDefault = {
    auto: 'format',
  };

  PreviewChanges = true;

  linkValue = '';

  Values: { [key: string]: string | number } = {};

  backLink: string;

  ImgixOptions: ImgixOption[] = [
    {
      option: 'Adjustments',
      settings: [
        {
          val: 'bri',
          default: 0,
          label: 'Brightness',
          tooltip: 'Adjusts the overall brightness of the image.',
          type: 'range',
          range: [-100, 100]
        },
        {
          val: 'con',
          default: 0,
          label: 'Contrast',
          tooltip: 'Adjusts the contrast of the image.',
          type: 'range',
          range: [-100, 100]
        },
        {
          val: 'exp',
          default: 0,
          label: 'Exposure',
          tooltip: 'Adjusts the exposure setting for an image, similar to changing the F-stop on a camera.',
          type: 'range',
          range: [-100, 100]
        },
        {
          val: 'gam',
          default: 0,
          label: 'Gamma',
          tooltip: 'Adjusts gamma and midtone brightness.',
          type: 'range',
          range: [-100, 100]
        },
        {
          val: 'high',
          default: 0,
          label: 'Highlights',
          tooltip: 'Adjusts the highlight tonal mapping of an image while preserving detail in highlighted areas.',
          type: 'range',
          range: [-100, 0]
        },
        {
          val: 'shad',
          default: 0,
          label: 'Shadows',
          tooltip: 'Adjusts the shadow tonal mapping of an image while preserving detail in shadowed areas.',
          type: 'range',
          range: [0, 100]
        },
        {
          val: 'hue',
          default: 0,
          label: 'Hue Shift',
          tooltip: 'Changes the hue, or tint, of each pixel in the image.',
          type: 'range',
          range: [0, 359]
        },
        {
          val: 'sat',
          default: 0,
          label: 'Saturation',
          tooltip: 'Adjusts the saturation of the image.',
          type: 'range',
          range: [-100, 100]
        },
        {
          val: 'vib',
          default: 0,
          label: 'Vibrance',
          tooltip: 'Adjusts the color saturation of an image while keeping pleasing skin tones.',
          type: 'range',
          range: [-100, 100]
        },
        {
          val: 'sharp',
          default: 0,
          label: 'Sharpen',
          tooltip: 'Sharpens the image using luminance (which only affects the black and white values), providing crisp detail with minimal color artifacts.',
          type: 'range',
          range: [0, 100]
        },
      ],
    },
    {
      option: 'Blending',
      settings: [
        {
          val: 'blend-color',
          label: 'Blend Color',
          tooltip: 'Blend a solid or transparent color over your image',
          type: 'color',
        },
        {
          val: 'blend-mode',
          label: 'Blend Mode',
          tooltip: `Blend modes determine how the color overlay will interact with the image.`,
          type: 'dropdown',
          options: [
            {
              label: 'Normal',
              value: 'normal',
            },
            {
              label: 'Darken',
              value: 'darken',
            },
            {
              label: 'Multiply',
              value: 'multiply',
            },
            {
              label: 'Burn',
              value: 'burn',
            },
            {
              label: 'Lighten',
              value: 'lighten',
            },
            {
              label: 'Screen',
              value: 'screen',
            },
            {
              label: 'Dodge',
              value: 'dodge',
            },
            {
              label: 'Overlay',
              value: 'overlay',
            },
            {
              label: 'Soft Light',
              value: 'softlight',
            },
            {
              label: 'Hard Light',
              value: 'hardlight',
            },
            {
              label: 'Difference',
              value: 'difference',
            },
            {
              label: 'Exclusion',
              value: 'exclusion',
            },
            {
              label: 'Color',
              value: 'color',
            },
            {
              label: 'Hue',
              value: 'hue',
            },
            {
              label: 'Saturation',
              value: 'saturation',
            },
            {
              label: 'Luminosity',
              value: 'luminosity',
            },
          ],
        },
      ],
    },
    {
      option: 'Corners',
      settings: [
        {
          val: 'corner-radius',
          label: 'Corner Radius',
          default: 0,
          tooltip: 'Sets a rounded corner radius in pixels.',
          range: [0, 500],
          type: 'number',
        },
      ]
    },
    {
      option: 'Focal Point Crop', // fp-debug
      settings: [
        {
          val: 'fp-debug',
          debug: true,
          default: false,
          label: 'Show Crosshair Helper',
          tooltip: 'Places a crosshair overlay on the image that identifies where the focal point of the image is set.',
          type: 'boolean',
        },
        {
          val: 'fp-x',
          default: 0.5,
          label: 'Focal Point X Position',
          tooltip: 'The horizontal or x value of the focal point of an image.',
          type: 'range',
          step: 0.01,
          range: [0, 1]
        },
        {
          val: 'fp-y',
          default: 0.5,
          label: 'Focal Point Y Position',
          tooltip: 'The vertical or y value of the focal point of an image.',
          type: 'range',
          step: 0.01,
          range: [0, 1]
        },
        {
          val: 'fp-z',
          default: 1,
          label: 'Focal Point Zoom',
          tooltip: 'The horizontal or x value of the focal point of an image.',
          type: 'range',
          step: 0.1,
          range: [1, 10]
        },
      ],
    },
    {
      option: 'Format',
      settings: [
        {
          val: 'q',
          label: 'Output Quality',
          default: 75,
          tooltip: 'Controls the output quality of lossy file formats (jpg, pjpg, webp, or jxr).',
          type: 'range',
          range: [1, 100]
        },
      ]
    },
    {
      option: 'Rotation',
      settings: [
        {
          val: 'rot',
          label: 'Rotation',
          default: 0,
          tooltip: 'Rotates the image by degrees according to the value specified.',
          type: 'range',
          step: 0.1,
          range: [0, 359]
        },
      ]
    },
    {
      option: 'Size',
      settings: [
        {
          val: 'crop',
          label: 'Crop Mode',
          default: 0,
          tooltip: 'Crop mode controls how the image is aligned',
          type: 'dropdown',
          options: [
            {
              value: 'top',
              label: 'Crop from the top of the image, down.',
            },
            {
              value: 'bottom',
              label: 'Crop from the bottom of the image, up.',
            },
            {
              value: 'left',
              label: 'Crop from the left of the image, right.',
            },
            {
              value: 'right',
              label: 'Crop from the right of the image, left.',
            },
            {
              value: 'faces',
              label: 'If faces are detected, attempts to center the crop to them.',
            },
            {
              value: 'focalpoint',
              label: 'Crop to the Focal Point.',
            },
            {
              value: 'entropy',
              label: 'Crop to something interesting (entropy).',
            },
            {
              value: 'edges',
              label: 'Crop to something interesting (edges).',
            },
          ],
        },
        {
          val: 'h',
          label: 'Image Height',
          default: 540,
          tooltip: 'The height of the output image.',
          type: 'number',
          range: [0, 2500]
        },
        {
          val: 'w',
          label: 'Image Width',
          default: 960,
          tooltip: 'The width of the output image.',
          type: 'number',
          range: [0, 2500]
        },
        {
          val: 'fit',
          label: 'Resize Fit Mode',
          default: 'crop',
          tooltip: 'The fit parameter controls how the output image is fit to its target dimensions after resizing, and how any background areas will be filled.',
          type: 'dropdown',
          options: [
            {
              value: 'clamp',
              label: 'Clamp',
            },
            {
              value: 'clip',
              label: 'Clip',
            },
            {
              value: 'crop',
              label: 'Crop',
            },
            {
              value: 'facearea',
              label: 'Face Area',
            },
          ],
        },
        {
          val: 'facepad',
          label: 'Face Padding',
          default: 1,
          tooltip: 'This parameter defines how much padding to allow for each face.',
          type: 'range',
          step: 0.1,
          range: [1, 20]
        },
      ]
    },
    {
      option: 'Stylize',
      settings: [
        {
          val: 'blur',
          label: 'Gaussian Blur',
          default: 0,
          tooltip: 'Applies a Gaussian style blur to your image, smoothing out image noise.',
          type: 'range',
          step: 0,
          range: [0, 500]
        },
        {
          val: 'monochrome',
          label: 'Monochrome',
          tooltip: 'Applies an overall monochromatic hue change.',
          type: 'color',
        },
        {
          val: 'sepia',
          label: 'Sepia Tone',
          tooltip: 'Applies an overall monochromatic hue change.',
          type: 'range',
          step: 0,
          range: [0, 100]
        },
      ]
    },
  ];

  checked = false;

  linkThrottled: () => void;

  FlatTypes: { [key: string]: ImgixOptionSetting } = {};

  constructor(
    private db: DbService,
    private utils: UtilsService,
    private ActiveRoute: ActivatedRoute,
    public dialog: MatDialog,
  ) { }

  shrinkDimensions(srcW, scrH, newMaxW) {
    const ratio = newMaxW / srcW;
    return {
      w: newMaxW,
      h: Math.round(ratio * scrH),
    };
  }

  ngOnInit(): void {

    this.reset();

    this.ActiveRoute.queryParamMap.subscribe(map => {
      this.backLink = map.get('back');
    });

    this.ActiveRoute.params.subscribe(async params => {
      if (params.imageID) {
        this.image = await this.db.get('Image', params.imageID);
        const d = this.shrinkDimensions(this.image.width, this.image.height, 500);
        this.Values.w = d.w;
        this.Values.h = d.h;
        this.link();
      }
    });

    this.linkThrottled = this.utils.lodash.throttle(this.link, 100);

    this.link();

    this.loadFavs();

  }

  async loadFavs() {
    this.favs = Array.from(await this.db.listAll('ImageSettingFav'));
  }

  applyFav(fav: ImageSettingFavType) {
    this.Values = fav.values;
    this.linkThrottled();
  }

  setCurFav(fav: ImageSettingFavType) {
    this.lastClickedFav = fav;
  }

  sortFavs() {
    this.favs.sort((a, b) => {
      return ('' + (a.name)).localeCompare((b.name));
    });
  }

  hexify(color: string) {
    const values = color
      .replace(/rgba?\(/, '')
      .replace(/\)/, '')
      .replace(/[\s+]/g, '')
      .split(',');

    const r = parseInt(values[0], 10);
    const g = parseInt(values[1], 10);
    const b = parseInt(values[2], 10);
    const a = values[3] ? parseFloat(values[3]) : 1;

    return ('00' + Math.round(255 * a).toString(16)).slice(-2) +
      ('00' + r.toString(16)).slice(-2) +
      ('00' + g.toString(16)).slice(-2) +
      ('00' + b.toString(16)).slice(-2);
  }

  roundToTwoDecimalPoints(value: number, digits: number = 2) {
    value = value * Math.pow(10, digits);
    value = Math.round(value);
    value = value / Math.pow(10, digits);
    return value;
  }

  dehexify(color: string) {
    color = color.replace(/[^a-zA-Z0-9]+/, '');
    const chunks = color.match(/.{1,2}/g);
    let a: number;
    let r: number;
    let g: number;
    let b: number;
    if (chunks.length === 4) {
      a = this.roundToTwoDecimalPoints(parseInt(chunks[0], 16) / 255);
      r = parseInt(chunks[1], 16);
      g = parseInt(chunks[2], 16);
      b = parseInt(chunks[3], 16);
      return `rgba(${r}, ${g}, ${b}, ${a})`;
    } else {
      r = parseInt(chunks[0], 16);
      g = parseInt(chunks[1], 16);
      b = parseInt(chunks[2], 16);
      return `rgba(${r}, ${g}, ${b})`;
    }
  }

  changedColor() {
    this.linkThrottled();
  }

  deleteFav() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Delete favourite, "' + this.lastClickedFav.name + '"?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.db.delete(this.lastClickedFav);

        this.utils.delete(this.favs, this.lastClickedFav);

        this.db.snackBar.open('Favourite deleted.', 'Dismiss', {
          duration: 6000
        });

      }
    });
  }

  editFav() {
    const fav = this.lastClickedFav;

    const dialogRef = this.dialog.open(PromptComponent, {
      width: '350px',
      data: {
        label: `Edit Favourite Label:`,
        value: fav.name,
      }
    });

    dialogRef.afterClosed().subscribe(newName => {
      if (newName) {
        fav.name = newName;
        this.db.put(fav);

        this.db.snackBar.open('Favourite saved.', 'Dismiss', {
          duration: 6000
        });

      }
    });

  }

  saveFav() {
    const dialogRef = this.dialog.open(PromptComponent, {
      width: '350px',
      data: {
        label: `Name for Favourite:`,
        value: `My Favourite`
      }
    });

    dialogRef.afterClosed().subscribe(newName => {
      if (newName) {
        const obj: ImageSettingFavType = {
          values: { ...this.Values },
          name: newName,
        };
        this.db.put('ImageSettingFav', obj);
        this.favs.push(obj);
        this.sortFavs();
      }
    });
  }

  reset() {
    this.Values = {};
    this.ImgixOptions.forEach(o => {
      o.settings.forEach(s => {
        this.FlatTypes[s.val] = s;
        if (typeof s.default !== 'undefined') {
          this.Values[s.val] = s.default;
        }
      });
    });
    if (this.image) {
      const d = this.shrinkDimensions(this.image.width, this.image.height, 500);
      this.Values.w = d.w;
      this.Values.h = d.h;
    }
    this.link();
  }

  setFromLink(event) {
    const QParams = this.linkValue.split('?')[1];
    console.log(QParams);
    this.Values = {};
    if (QParams) {
      const bits = QParams.split('&');
      bits.forEach(b => {
        const kv = b.split('=');
        const key = kv[0];
        if (this.FlatTypes[key]) {
          let val = kv[1];
          if (key === 'corner-radius') {
            val = val.split(',')[0];
          } else if (this.FlatTypes[key].type === 'color') {
            val = this.dehexify(val);
          }
          this.Values[key] = val;
        }
      });
    }
    this.link();
  }

  link() {

    if (this.image) {

      const vals = [];

      const alwaysTypes = [
        'w', 'h', 'auto', 'fit'
      ];

      Object.keys(this.Values).forEach(k => {
        let val = this.Values[k];
        if (
          (this.PreviewChanges && (
            (this.FlatTypes[k].default !== val || alwaysTypes.includes(k)) &&
            val !== null
          )) || (
            !this.PreviewChanges && alwaysTypes.includes(k)
          )
        ) {
          if (this.FlatTypes[k].type === 'color') {

            val = this.hexify(val as string);

          } else if (this.FlatTypes[k].val === 'corner-radius') {

            val = `${val},${val},${val},${val}`;
            vals.push(`mask=corners`);

          } else if (typeof val === 'boolean') {

            val = val ? 'true' : 'false';

          }
          vals.push(`${k}=${val}`);

        }
      });

      Object.keys(this.ImgixDefault).forEach(k => {
        const val = this.ImgixDefault[k];
        vals.push(`${k}=${val}`);
      });

      this.linkValue = this.image.host + this.image.path + '?' + vals.join('&');

      return this.linkValue;
    }
  }

}
