import { Component, OnInit, Input } from '@angular/core';
import { ThemeColor, ThemeColors } from '../modules/colors';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {

  @Input() theme = null;
  @Input() image: string;
  color: ThemeColor;

  constructor() { }

  ngOnInit(): void {
    if (this.theme) {
      this.color = ThemeColors[this.theme];
    }
  }

}
