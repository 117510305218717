import { ImageType } from '../services/DataStore';
export class ImgixHepler {

    image: {
        params: {
            [key: string]: string|number
        },
        url: string
    };

    url: URL;

    constructor() {}

    w(num: number) {
        this.image.params.w = num;
    }
    blur(num: number) {
        this.image.params.blur = num;
    }
    h(num: number) {
        this.image.params.h = num;
    }
    auto(format: string) {
        this.image.params.auto = format;
    }

    output(): string {

        const ParamArray = [];

        Object.keys(this.image.params).forEach(key => {
            const val = this.image.params[key];
            ParamArray.push(key + '=' + val);
        });

        return this.image.url + '?' + ParamArray.join('&');
    }

    UrlWithoutSearch() {
        return this.url.href.replace(this.url.search, '');
    }

    parse(url: string) {        

        this.url = new URL(url);

        const paramMap: any = {};

        const KeyBlacklist = [
            'cs', 'fm', 'q'
        ];

        this.url.searchParams.forEach((param, key) => {
            if (!KeyBlacklist.includes(key)) {
                paramMap[key] = param;
            }
        });

        this.image = {
            params: paramMap,
            url: this.UrlWithoutSearch()
        };

    }

    concatUrl(image: ImageType) {
        return image.host + image.path + (image.params ? '?' + image.params : '');
    }

    
    defaults(params: string) {
        // params is a get params string, e.g. w=2&id=xsoe3&ix2=14234

        const IndividualParams = params.split('&');

        for (const param of IndividualParams) {

            const ParamKeyVal = param.split('=');

            if (typeof this.image.params[ParamKeyVal[0]] === 'undefined') {
                this.image.params[ParamKeyVal[0]] = ParamKeyVal[1];
            }
        }

    }

    urlFromImage(image: ImageType) {

        this.image = {
            params: {},
            url: ''
        };

        if (image.params) {
            const IndividualParams = image.params.split('&');
            for (const param of IndividualParams) {
                const ParamKeyVal = param.split('=');
                this.image.params[ParamKeyVal[0]] = ParamKeyVal[1];
            }
        }

        return this.output();

    }

}
