import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  IsAuthenticated = false;
  IsTopAdmin = false;

  onAuthChange = AuthState => {
    this.IsAuthenticated = AuthState;
    this.IsTopAdmin = this.authService.isTopAdmin();
  }

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.authService.onStateChange(this.onAuthChange);
  }

  ngOnDestroy() {
    this.authService.removeStateChangeCallback(this.onAuthChange);
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('');
  }


}
