<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content class="mat-typography">

    <nav class="crumbs">
        <span>
            <a (click)="navigate(null)">Home</a>
        </span>
        <span *ngFor="let item of tree; let last = last" class="ilb">
            <mat-icon>chevron_right</mat-icon>
            <a (click)="navigate(item)">{{ item.name }}</a>
        </span>
    </nav>

    <div class="folders">
        <div class="folder" *ngFor="let folder of folders" [hidden]="folder == data.restrict">
            <div class="wrapper ilb" (click)="navigate(folder)" mat-ripple>
                <mat-icon>folder</mat-icon>
                <span class="label">{{ folder.name }}</span>
            </div>
        </div>
    </div>

    <div class="images">
        <div class="image" *ngFor="let image of items">
            <div class="wrapper" mat-ripple (click)="selectImage(image)">
                <img [src]="image.host + image.path + '?fit=crop&w=250&h=200&auto=format'" />
                <div class="label ilb">
                    <mat-icon>image</mat-icon>
                    <span>{{ image.name }}</span>
                </div>
            </div>
        </div>
    </div>

</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Close</button>
</div>