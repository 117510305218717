import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements OnInit {

  siteTitle = 'The Maker Heals';
  websiteDomain = 'www.makerheals.org.au';
  sitePhone1 = '0468 3211 559';
  siteEmail = 'mail@madison.org.au';
  siteAddress = '19 Westleys Rd, Faulkland NSW 2422';

  constructor(
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Terms of Service - The Maker Heals');
  }

}
