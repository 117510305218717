<app-back-header title="Image Editor" padding="50px" theme="grey50" [back]="backLink"></app-back-header>

<div class="inner">
    <div class="accordion">
        <mat-accordion>
            <mat-expansion-panel *ngFor="let op of ImgixOptions">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ op.option }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="setting" [ngClass]="st.type" *ngFor="let st of op.settings">
                    <div class="label" [matTooltip]="st.tooltip">{{ st.label }}</div>
                    <div class="input" [ngSwitch]="st.type">
                        <div class="range" *ngSwitchCase="'range'">
                            <mat-slider (dblclick)="Values[st.val] = FlatTypes[st.val].default; linkThrottled();" [min]="st.range[0]" [max]="st.range[1]" [step]="st.step || 1" [thumbLabel]="true" [(ngModel)]="Values[st.val]" (ngModelChange)="linkThrottled()">
                            </mat-slider>
                        </div>
                        <div class="number" *ngSwitchCase="'number'">
                            <input type="number" [min]="st.range[0]" [max]="st.range[1]" [(ngModel)]="Values[st.val]" (ngModelChange)="linkThrottled()">
                        </div>
                        <div class="boolean" *ngSwitchCase="'boolean'">
                            <mat-slide-toggle [(ngModel)]="Values[st.val]" (ngModelChange)="linkThrottled()"></mat-slide-toggle>
                        </div>
                        <div class="dropdown" *ngSwitchCase="'dropdown'">
                            <mat-select [(ngModel)]="Values[st.val]" (ngModelChange)="linkThrottled()">
                                <mat-option [value]='null'>None</mat-option>
                                <mat-option [value]="option.value" *ngFor="let option of st.options">{{ option.label }}</mat-option>
                            </mat-select>
                        </div>
                        <div class="color" *ngSwitchCase="'color'">
                            <input (colorPickerChange)="changedColor()" [(colorPicker)]="Values[st.val]" [style.background]="Values[st.val]" cpOutputFormat="rgba" />
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="image" *ngIf="image">
        <div class="btns">
            <div>
                <mat-slide-toggle [(ngModel)]="PreviewChanges" (ngModelChange)="linkThrottled()"></mat-slide-toggle> <span class="label">Preview Changes</span>
            </div>
            <div>
                <button mat-button (click)="reset()">RESET</button>
            </div>
        </div>
        <input type="text" [(ngModel)]="linkValue" (ngModelChange)="setFromLink($event)">
        <img [src]="linkValue" alt="">
    </div>
    <div class="favs">
        <mat-menu #favMenu="matMenu">
            <button mat-menu-item (click)="editFav()">
                <mat-icon>edit</mat-icon>
                Edit
            </button>
            <button mat-menu-item (click)="deleteFav()">
                <app-icon>delete</app-icon>
                Delete
            </button>
        </mat-menu>
        <h2>Favourites</h2>
        <div class="fav" *ngFor="let fav of favs" (click)="setCurFav(fav)">
            <button mat-button (click)="applyFav(fav);">{{ fav.name }}</button>
            <button mat-icon-button [matMenuTriggerFor]="favMenu">
                <mat-icon>arrow_drop_down</mat-icon>
            </button>
        </div>
    </div>
</div>
<button mat-raised-button color="primary" (click)="saveFav()" class="mat-elevation-z10 pill">
    <mat-icon>add</mat-icon> Save Current Settings
</button>
<div>
    <!-- <button mat-button (click)="onNoClick()">Cancel</button> -->
    <!-- <button mat-button color="primary" (click)="copy()">Copy Url!</button> -->
</div>