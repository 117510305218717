<app-menu></app-menu>
<app-fullscreen-image src="../../../../assets/AdobeStock_292955788.jpg">
    <div class="wrapper">


        <div class="card mat-elevation-z8">

            <h1>Signup</h1>
            <form [formGroup]="signupForm" autocomplete="off" novalidate (ngSubmit)="signup(signupForm.value)">

                <mat-form-field class="field">
                    <input matInput tabindex="1" formControlName="first" placeholder="Firstname" type="text">
                    <mat-error *ngIf="hasError('first', 'required')">Firstname is required</mat-error>
                </mat-form-field>

                <mat-form-field class="field">
                    <input matInput tabindex="2" formControlName="last" placeholder="Lastname" type="text">
                    <mat-error *ngIf="hasError('last', 'required')">Lastname is required</mat-error>
                </mat-form-field>

                <mat-form-field class="field">
                    <input matInput tabindex="3" formControlName="email" placeholder="Email" type="text">
                    <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error>
                    <mat-error *ngIf="hasError('email', 'email')">Invalid email</mat-error>
                </mat-form-field>

                <mat-form-field class="field" style="margin-bottom: 20px;">
                    <input matInput tabindex="4" formControlName="password" placeholder="Password" [type]="hidePwd ? 'password' : 'text'">
                    <button type="button" mat-icon-button matSuffix (click)="hidePwd = !hidePwd" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePwd">
                <mat-icon>{{hidePwd ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
                    <mat-hint>Must be at least six characters with a letter and a number.</mat-hint>
                    <mat-error *ngIf="hasError('password', 'required')">Password is required</mat-error>
                    <mat-error *ngIf="hasError('password', 'pattern')">Must be at least six characters with a letter and a number.</mat-error>
                </mat-form-field>

                <p>
                    <button type="submit" tabindex="5" mat-raised-button color="primary" [disabled]="activity || !signupForm.valid">Signup</button>
                </p>

                <div>
                    Already have an account? <a routerLink="/login">Login</a>
                </div>

            </form>

        </div>
    </div>
</app-fullscreen-image>
<app-footer></app-footer>