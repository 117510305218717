<app-menu></app-menu>
<app-back-header title="About Madison Missions" back="" theme="charcoal"></app-back-header>
<div class="wrapper">
    <p>Madison Missions is the parent organisation behind The Maker Heals. Madison is a charity organisation based in Victoria, Australia</p>
    <p>The causes we are passionate about are Health &amp; Healing, Education, Small-Business Empowerment, and a simple but strong faith in the Bible.</p>
    <p>Our activities include running The Maker Heals program, Beehive Community Services, Youth Camps & sharing the Seventh-day Adventist Christian faith.</p>
    <p>Madison Missions is fundraising to establish an education and health facility.</p>
    <p>You can find out a lot more about what we do, on our Madison Missions website:</p>
    <p><app-button label="Madison.org.au" (click)="open()" theme="primary" style="display: inline-block;"></app-button></p>
    <div class="logo-grid" style="margin-top: 50px;">
        <div class="img">
            <img src="https://cdn2.tda.website/beehive/20200607/Madison-Missions-Logo.svg" alt="Madison Missions Australia Ltd." class="madison">
        </div>
        <div class="img">
            <img src="https://cdn2.tda.website/beehive/20200607/Eastward-logo.svg" alt="Eastward Missions" class="eastward">
        </div>
        <div class="img">
            <img src="https://cdn2.tda.website/beehive/20200607/The-Maker-Heals-Logo.svg" alt="The Maker Heals" class="makerheals">
        </div>
        <div class="img">
            <img src="https://cdn2.tda.website/beehive/20200607/Beehive-Community-Services-Logo.svg" alt="Beehive Community Services" class="beehive">
        </div>
    </div>

</div>
<app-share></app-share>
<app-subscribe></app-subscribe>
<app-footer></app-footer>