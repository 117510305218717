<app-menu></app-menu>
<app-section>
    <img src="https://cdn2.tda.website/beehive/20210527/Maker-Heals-Appstore-80.jpg?w=1000&fit=crop&auto=format" alt="">
    <p style="text-align: center;">Digital program coming soon!</p>
</app-section>
<div class="wrapper" style="width: 100%; max-width: 100%;box-sizing: border-box;">
    <h1>Ph<span class="alt-font">o</span>tos</h1>
    <div class="images">
        <img *ngFor="let i of Images" [src]="i + settings">
    </div>
</div>
<app-subscribe></app-subscribe>
<app-footer></app-footer>