import { Component, OnInit, Inject } from '@angular/core';

import { WINDOW } from 'src/app/services/window.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-madison',
  templateUrl: './madison.component.html',
  styleUrls: ['./madison.component.scss']
})
export class MadisonComponent implements OnInit {

  constructor(
    @Inject(WINDOW) private window: Window,
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('About Madison Missions - The Maker Heals');
  }

  open() {
    this.window.open('https://madison.org.au/');
  }

}
