import { Component, OnInit, ViewChild } from '@angular/core';
import { StripeToken, StripeCard } from 'stripe-angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DonationPmtService } from 'src/app/services/donationPmt.service';
import { DbService } from 'src/app/services/db.service';
import { PersonType, FrequencyString, DonationType } from 'src/app/services/DataStore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss']
})
export class DonateComponent implements OnInit {

  @ViewChild('stripeCard') stripeCard: StripeCard;

  cardToken: StripeToken;
  invalidError: Error;
  donateForm: FormGroup;

  isProcessing = false;

  presets = [50, 100, 250, 500, 1000, 2500, 5000, 10000];

  amount = 100;
  frequency: FrequencyString = 'onetime';

  roundUp = false;

  constructor(
    public snackBar: MatSnackBar,
    private payments: DonationPmtService,
    private db: DbService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.donateForm = new FormGroup({

      first: new FormControl('', [Validators.required]),
      last: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      comments: new FormControl('', []),

    });

  }

  onStripeError(error: Error) {
    console.error('Stripe error', error, this.cardToken);
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.donateForm.controls[controlName].hasError(errorName);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid:not(form)');
    this.scrollTo(firstElementWithError);
  }

  async scrollIfFormHasErrors(form: FormGroup): Promise<any> {
    this.scrollToError();
  }

  handleStripeError(obj: object): boolean {
    console.log('HandleStripeError', obj);

    if (obj['type'] === 'StripeCardError') {

      const Error = obj['raw'] as any;

      this.snackBar.open('Card Error: ' + Error.message, 'Dismiss', {
        duration: 12000,
      });

      return true;

    }
    return false;
  }

  touchForm(form: FormGroup) {
    // touch all controls, so invalid fields are highlighted.
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  getAmount() {
    return parseFloat((this.roundUp ? this.getRoundedUp() : this.amount).toFixed(2));
  }

  getRoundedUp() {
    return this.amount + (this.amount * 0.0185) + 0.3;
  }

  async continue() {
    const Form = this.donateForm.value;

    const First = Form.first;
    const Last = Form.last;
    const Email = Form.email;
    const Comments = Form.comments || '';

    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;

    if (!this.donateForm.valid) {
      this.touchForm(this.donateForm);
      this.scrollIfFormHasErrors(this.donateForm);

      this.snackBar.open('Please complete all required fields.', 'Dismiss', {
        duration: 6000,
      });

      this.isProcessing = false;

      return;
    }

    try {

      const CardToken = await this.stripeCard.createToken();

      if (CardToken) {

        if (this.frequency === 'monthly') {

          const requestForPersonData = {
            First,
            Last,
            Email,
            source: 'MakerHeals',
            sendEmail: 'false'
          };

          const person = (
            await this.db.q('auth:GetPersonForDonation', requestForPersonData)
          ).Data as PersonType;

          const res: any = await this.payments.recurringDonation(
            CardToken,
            this.getAmount(),
            person.ID,
            Comments,
          );

          if (this.handleStripeError(res)) {
            this.isProcessing = false;
            return;
          }

          if (res.Success) {

            const ID = res.Donation.ID as string;

            requestForPersonData.sendEmail = 'true';
            await this.db.q('auth:GetPersonForDonation', requestForPersonData);

            this.router.navigateByUrl('/view-donation/' + ID);
          }

          this.isProcessing = false;

        } else {

          const res2: any = await this.payments.oneTime(
            CardToken,
            this.getAmount(),
            { First, Last, Email, },
            'MakerHeals',
            this.frequency,
            Comments
          );

          this.isProcessing = false;

          if (!this.handleStripeError(res2)) {

            if (res2.paid) {
              const ID = res2.DonationComplexID;
              this.router.navigateByUrl('/view-donation/' + ID);
            }

            // this.authService.person = res2['Person'] as PersonType;
            // this.MyPaymentSources = this.authService.person.StripeCustomer.sources.data;
            // this.selectedPaymentSource = this.utils.find(this.MyPaymentSources, 'id', CardToken.card.id);

          } else {

            this.isProcessing = false;
            return;

          }

        }

      } else {

        this.snackBar.open('Invalid payment details.', 'Dismiss', {
          duration: 6000,
        });

        this.isProcessing = false;
        return;

      }

    } catch (e) {

      this.isProcessing = false;

      if (e.message) {
        this.snackBar.open('ERROR: ' + e.message, 'Dismiss', {
          duration: 12000,
        });
      } else {
        this.snackBar.open('ERROR: Card error.', 'Dismiss', {
          duration: 12000,
        });
      }

    }

  }

}
