<div mat-dialog-content class="mat-typography">
    <form  [formGroup]="prompt" (ngSubmit)="confirm()">
        <mat-form-field style="width: 100%;">
            <input matInput formControlName="input" cdkFocusInitial #input="matInput" [placeholder]="data.label">
            <mat-error *ngIf="hasError('input', 'required')">This is required</mat-error>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button color="primary" [disabled]="!prompt.valid" (click)="confirm()">Confirm</button>
</div>